import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setquestiondetail, clearquestiondetail } from "../features/QuestionDetailSlice";

import closeIcon from '../assets/images/cross-close.svg';
import QuestionAnsDeleteIcon from '../assets/images/question-ans-delete-icon.svg';
import addMoreChoicseIcon from '../assets/images/add-more-choices-icon.svg';
import { useState } from "react";
import { useEffect } from "react";

export const TypeOfAnswer = ({ closeQuestionCreationForm, moveToNextStep, moveToPreviousStep, languages }) => {

    const [addMoreChoicesRow, setAddMoreChoicesRow] = useState([]);
    const [questionToBeSaved, setQuestionToBeSaved] = useState(null);
    const [questionType, setQuestionType] = useState(1);
    const [emptyFieldMessage, setEmptyFieldMessage] = useState("");

    const dispatch = useDispatch();

    const reduxQuestionDetail = useSelector(state => state.questionDetail.questionDetail);

    useEffect(() => {
        if (reduxQuestionDetail && questionToBeSaved == null) {
            setQuestionToBeSaved(reduxQuestionDetail);
            if (reduxQuestionDetail.questionDetail.answerType) {
                setQuestionType(reduxQuestionDetail.questionDetail.answerType);
            }
        }
    }, [reduxQuestionDetail])

    const addMoreChoiceHandle = (e) => {
        let targetId = e != undefined ? e.target.id : "";
        if (targetId != "" || (reduxQuestionDetail.questionDetail.answerOptions == undefined || reduxQuestionDetail.questionDetail.answerOptions.length == 0)) {
            let newId = addMoreChoicesRow.length + 1;

            while (addMoreChoicesRow.some(row => row.id === newId)) {
                newId++;
            }

            const addRow = {
                id: newId
            }

            languages.forEach(item => {
                addRow["option" + newId + "-" + item.languageCode] = "";
            })

            setAddMoreChoicesRow([...addMoreChoicesRow, addRow]);
        }
        else {
            setAddMoreChoicesRow(reduxQuestionDetail.questionDetail.answerOptions);
        }
    }

    useEffect(() => {
        if (addMoreChoicesRow.length == 0) {
            addMoreChoiceHandle();
        }
    }, []);

    const deleteLanguageChoiceRow = (rowId) => {
        if (addMoreChoicesRow.length == 1) {
            return;
        }
        const removeLanguageChoiceField = addMoreChoicesRow.filter((item) => item.id != rowId);
        setAddMoreChoicesRow(removeLanguageChoiceField);
    }

    const handleInputChange = (id, field, value) => {
        setEmptyFieldMessage("");
        setAddMoreChoicesRow(addMoreChoicesRow.map(row => row.id === id ? { ...row, [field]: value } : row));
    };

    const clickHandleNextButton = () => {
        let objQuestion = JSON.parse(JSON.stringify(questionToBeSaved));

        let hasAnyEmptyValue = addMoreChoicesRow.some(obj =>
            Object.values(obj).some(value => value === '')
        );
        if (hasAnyEmptyValue && (questionType == 2 || questionType == 3)) {
            setEmptyFieldMessage("Some fields are empty. Please fill out all fields.");
            return;
        }

        objQuestion.questionDetail.answerType = questionType;
        if (questionType == 2 || questionType == 3) {
            objQuestion.questionDetail.answerOptions = addMoreChoicesRow;
        }
        else {
            objQuestion.questionDetail.answerOptions = [];
        }

        objQuestion.step = objQuestion.step + 1;

        setQuestionToBeSaved(objQuestion);
        moveToNextStep(objQuestion);
    }

    const handleKeyDown = (e) => {
        // Prevent up and down arrow keys from modifying the value
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };

    const clickHandleBackButton = () => {
        moveToPreviousStep();
    }

    const onChangeQuestionType = (e) => {
        let value = e.target.value;
        setQuestionType(+value);
        setEmptyFieldMessage("");
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                    <li className="step-bar"></li>
                </ul>
                <img src={closeIcon} onClick={closeQuestionCreationForm} className="cursor-pointer" />
            </div>
            <div>
                <p className="form-title mb-2"> Question: Creation </p>
            </div>
            <div className="mb-4">
                <p className="subtitle mb-1">Type of answers</p>
                <p className="modal-p-text-black mb-4">Lorem ipsum dolor sit amet consectetur. Ullamcorper netus sed est lorem. Facilisis dictum bibendum tellus eu eget porttitor. Arcu eget molestie sed consectetur integer. Risus malesuada id lacus egestas. Sit maecenas iaculis nibh eget habitasse ac. Fermentum pellentesque mattis enim adipiscing aliquet. Risus tellus augue lectus sit eu pharetra. Mattis fermentum nibh viverra egestas fames. Ut ut in mattis blandit magna. Laoreet arcu morbi.</p>
            </div>
            <div className="row align-items-baseline mb-4">
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <p className="modal-p-text-black">Choose the type</p>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5">
                    <select className="form-select " aria-label="Default select example" value={questionType} onChange={onChangeQuestionType}>
                        <option value="1">Radio button</option>
                        <option value="2">List of numeric answers</option>
                        <option value="3">List of prefilled answers (alphanumeric)</option>
                        <option value="4">Open numeric answer</option>
                        <option value="5">Free answer (alphanumeric)</option>
                        <option value="6">Date</option>
                        {/*<option value="7">Comments box</option>*/}
                    </select>
                </div>
            </div>
            <div className="question-choices-container p-3 mb-4">
                <span className={`text-red fs-small ${emptyFieldMessage != "" ? "login-field-error px-2" : ""}`}>{emptyFieldMessage}</span>
                <table className="table table-borderless" style={{ '--bs-table-bg': 'transparent' }}>
                    <thead>
                        {
                            questionType == 1 ?
                                <tr className="text-center">
                                    {
                                        languages ? languages.map(item => {
                                            return (
                                                <th className="modal-p-text-black" key={item.id}>{item.languageDescription}</th>
                                            )
                                        })
                                            :
                                            <>
                                            </>
                                    }
                                </tr>
                                :
                                questionType == 2 || questionType == 3 ?

                                    <tr className="text-center">
                                        {
                                            languages ? languages.map(item => {
                                                return (
                                                    <th className="modal-p-text-black" key={item.id}>{item.languageDescription}</th>
                                                )
                                            })
                                                :
                                                <>
                                                </>
                                        }
                                        <th className="modal-p-text-black"></th>
                                    </tr>
                                    :
                                    <tr className="text-center">
                                        <th className="modal-p-text-black"></th>
                                    </tr>
                        }
                    </thead>
                    <>
                        {
                            questionType == 1 && languages ?
                                <tbody>
                                    <tr>
                                        {
                                            languages.map(item => {
                                                return (
                                                    <td key={item.id}>
                                                        <div className="d-flex">
                                                            <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radio${item.id}`}>
                                                                <input className="custom-radio me-2" type="radio" name={`radio${item.id}`} id={`radio${item.id}`} />
                                                                Yes
                                                            </label>

                                                            <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radio${item.id}${questionType}`}>
                                                                <input className="custom-radio me-2" type="radio" name={`radio${item.id}`} id={`radio${item.id}${questionType}`} />
                                                                No
                                                            </label>
                                                        </div>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </tbody>
                                :
                                questionType == 2 || questionType == 3 ?
                                    <tbody>
                                        {
                                            addMoreChoicesRow.length > 0 ?
                                                addMoreChoicesRow.map((row, rowIndex) => {
                                                    return (
                                                        <tr key={row.id}>
                                                            {

                                                                languages ? languages.map(item => {
                                                                    let value = row["option" + row.id + "-" + item.languageCode];
                                                                    return (
                                                                        <td key={item.id}>
                                                                            <input type={questionType == 2 ? "number" : "text"} className={`form-control mb-2${questionType == 2 ? " no-spinner" : ""}`}
                                                                                defaultValue={row["option" + row.id + "-" + item.languageCode]}
                                                                                onPaste={(e) => e.preventDefault()} onKeyDown={handleKeyDown}
                                                                                onChange={(e) => handleInputChange(row.id, `option${row.id}-${item.languageCode}`, e.target.value)} />
                                                                        </td>
                                                                    )
                                                                })
                                                                    :
                                                                    <></>
                                                            }
                                                            <td>
                                                                <p className="ans-delete-icon bg-danger rounded-5 text-white mb-0">
                                                                    <img src={QuestionAnsDeleteIcon} onClick={() => deleteLanguageChoiceRow(row.id)} />
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                ""
                                        }
                                    </tbody>
                                    :
                                    questionType == 4 || questionType == 5 ?
                                        <tbody>
                                            <tr>
                                                <td><input type={questionType == 4 ? "number" : "text"} className={`form-control mb-2${questionType == 4 ? " no-spinner" : ""}`} disabled /></td>
                                            </tr>
                                        </tbody>
                                        :
                                        <tbody>
                                            <tr>
                                                <td><input type="date" className="form-control bg-white mb-2" /></td>
                                            </tr>
                                        </tbody>
                        }
                    </>
                </table>
                {
                    questionType == 2 || questionType == 3 ?
                        <div className="d-flex justify-content-center w-75 mx-auto">
                            <button className="btn create-question-btn d-flex justify-content-center align-items-center w-50" id="addChoiceBtn" onClick={addMoreChoiceHandle}><img src={addMoreChoicseIcon} className="me-2" /> Add more choices</button>
                        </div>
                        :
                        <></>
                }

            </div>

            <div className="d-flex justify-content-center">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-25 me-3" onClick={clickHandleBackButton}>Back</button>
                <button type="button" className="site-btn site-btn-padd w-25" onClick={clickHandleNextButton}>Next</button>
            </div>
        </>
    )
}
