import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import { variables } from '../Variables';
import { useSelector } from "react-redux";

import mindfreeLogo from '../assets/images/login_Logo.svg';
import dateIcon from '../assets/images/date-white-icon.svg';
import timeIcon from '../assets/images/time-white-icon.svg';
import successImg from '../assets/images/success-page-img.png';

import bedIcon from '../assets/images/bed-duo.svg';
import showerIcon from '../assets/images/shower-duo.svg';
import liftIcon from '../assets/images/lift-duo.svg';
import locationPin from '../assets/images/location-pin.svg';
import placeholderLarge from '../assets/images/prop-placeholder-lg.jpg';
import agentBlack from '../assets/images/agents-black.svg';

export const EventDenialHandler = () => {

    const { contactId, eventId, clientId, officeId, contactName, contactLanguage } = useParams();
    const [clientToken, setClientToken] = useState('');
    const [whiseUserToken, setWhiseUserToken] = useState('');
    const [whiseCalendarEvents, setWhiseCalendarEvents] = useState([]);
    const [whiseCalendarEventDeleted, setWhiseCalendarEventDeleted] = useState([]);
    const [confirmedIds, setConfirmedIds] = useState([]);
    const [tobeConfirmedIds, setTobeConfirmedIds] = useState([]);
    const [freeSlotIds, setFreeSlotIds] = useState([]);
    const [calendarActions, setCalendarActions] = useState([]);
    const [contactTitleList, setContactTitleList] = useState([]);
    const [estate, setEstate] = useState([]);
    const [whiseClientSettings, setWhiseClientSettings] = useState(null);
    const [property, setProperty] = useState(null);
    const [dummyContactIdForFreeSlot, setDummyContactIdForFreeSlot] = useState(null);

    const [selectReason, setSelectReason] = useState("");
    const [selectAction, setSelectAction] = useState("");
    const [reasonMessage, setReasonMessage] = useState("");
    const [regionEmpty, setRegionEmpty] = useState(false);
    const [actionEmpty, setActionEmpty] = useState(false);
    const [messageEmpty, setMessageEmpty] = useState(false);
    const [contactEmail, setContactEmail] = useState("");
    const [selectReasonText, setSelectReasonText] = useState("");
    const [formSubmit, setFormSubmit] = useState(false);
    const [textareaEnable, setTextareaEnable] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const getToken = async () => {
        let url = "https://api.whise.eu/token";
        let headers = {
            'Content-Type': 'application/json'
        };
        let body = {
            "Username": variables.WhiseUser,
            "Password": variables.WhisePassword
        };

        try {
            let resp = await axios.post(url, body,
                {
                    headers: headers
                });
            if (resp && resp.data && resp.data.token) {
                setWhiseUserToken(resp.data.token);
                getClientToken(resp.data.token);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getClientToken = (token) => {
        let url = "https://api.whise.eu/v1/admin/clients/token";

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": clientId }, config)
            .then(response => {
                setClientToken(response.data.token);
                getCalendarActions(response.data.token);
                getClientSettings(token, clientId);
                getWhiseCalenderEventById(response.data.token);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getCalendarActions = (token) => {
        let url = "https://api.whise.eu/v1/calendars/actions/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                setCalendarActions(response.data.calendarActions);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseCalenderEventById = (client_Token) => {
        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${client_Token}`,
                'Content-Type': 'application/json'
            },
        };
        let body = {
            "Filter": {
                "CalendarIds": [+eventId],
                "IncludeRecurrences": true
            }
        };
        axios.post(url, body, config)
            .then(response => {
                if (response.data.calendars) {
                    console.log(response.data.calendars)
                    setWhiseCalendarEvents(response.data.calendars);
                    getClientWhiseEstatesList(response.data.calendars[0].estates[0].estateId, client_Token);
                    setContactEmail(response.data.calendars[0].contacts[0].privateEmail);
                } else {
                    setWhiseCalendarEvents([]);
                }

            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getClientSettings = (token, clientId) => {
        let url = 'https://api.whise.eu/v1/admin/clients/settings';

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        let body = {
            ClientId: clientId
        };

        axios.post(url, body,
            {
                headers: headers
            })
            .then(response => {
                if (response.data.settings) {
                    setWhiseClientSettings(response.data.settings);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getClientWhiseEstatesList = (estateId, client_Token) => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${client_Token}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {
            "Filter": {
                "StatusIds": [1],
                "PurposeStatusIds": [1, 2, 5, 6, 10, 11, 12, 13, 15, 16, 20, 21, 27, 28],
                "DisplayStatusIds": [1, 2, 3, 4, 5],
                "EstateIds": [+estateId]
            }
        }, config)
            .then(response => {
                setEstate(response.data.estates[0]);
                setProperty(response.data.estates);
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const getOfficeSettings = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetLocalSetting?officeId=` + officeId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let settings = response.data;
                let toBeConfirmed = [settings.visitToSaleBefore, settings.visitToRentBefore];
                let cofirmed = [settings.visitToSaleAfter, settings.visitToRentAfter];
                let freeslot = [settings.visitToSaleFreeSlot, settings.visitToRentFreeSlot];

                setTobeConfirmedIds(toBeConfirmed);
                setConfirmedIds(cofirmed);
                setFreeSlotIds(freeslot);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const DeleteWhiseCalenerEvent = () => {
        setButtonDisabled(true);
        let url = "https://api.whise.eu/v1/calendars/delete";
        let headers = {
            'Authorization': `Bearer ${clientToken}`,
            'Content-Type': 'application/json'
        };
        let body = {
            "CalendarIds": [+eventId]
        };
        axios.delete(url, { data: body, headers: headers })
            .then(response => {
                if (response.data) {
                    setWhiseCalendarEventDeleted(response.data);
                    resetAllFields();
                    setFormSubmit(true);
                    setButtonDisabled(false);
                }
            })
            .catch(error => {
                console.log("error", error)
                setButtonDisabled(false);
            });
    }

    const deleteEstateAllFreeSlots = () => {
        setButtonDisabled(true);
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        let url = variables.API_URL + `calendar/DeleteEstateAllFreeSlots?clientId=${clientId}&estateId=${estate.id}`;

        axios.post(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                console.log(response.data);
                setButtonDisabled(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setButtonDisabled(false);
            });
    }

    const getContactTitleList = () => {
        let url = "https://api.whise.eu/v1/contacts/titles/list";

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${clientToken}`
        };

        let body = {};

        axios.post(url, body,
            {
                headers: headers
            })
            .then(response => {
                if (response.data) {
                    setContactTitleList(response.data.contactTitles);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    useEffect(() => {
        if (whiseUserToken == '') {
            getToken();
            getOfficeSettings();
        }
    }, [whiseUserToken])

    useEffect(() => {
        if (clientId) {
            getDummyContactId();
        }
    }, [clientId])

    const getDummyContactId = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `User/GetContactIdOfClient?clientId=`+clientId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let dummyContactId = response.data;
                if (dummyContactId) {
                    setDummyContactIdForFreeSlot(dummyContactId);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const emailSendToContact = () => {

        if (selectReason == "" && reasonMessage == "" && selectAction == "") {
            setRegionEmpty(true);
            setMessageEmpty(true);
            setActionEmpty(true);
            return;
        }

        if (selectReason == "") {
            setRegionEmpty(true);
            return;
        }

        if (reasonMessage == "" && textareaEnable == false) {
            setMessageEmpty(true);
            return;
        }

        if (selectAction == "") {
            setActionEmpty(true);
            return;
        }

        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        let html = "";
        html += "<table width='600' cellspacing='0' cellpadding='0' border='0' style='border: 1px solid gray; background-color: white; border-radius: 16px; margin: 0px auto;'>";
        html += "<tr>";
        html += "   <td style='text-align: center; padding: 30px 0px 50px 0px;'>";
        if (whiseClientSettings != null && whiseClientSettings.logoUrl != undefined) {
            html += "   <img src='" + whiseClientSettings.logoUrl + "' width='200' style='margin: 0 auto;' />";
        } else {
            html += "   <img src='" + window.location.origin + "/assets/logo-placeholder.png' width='200' style='margin: 0 auto;' />";
        }
        html += "   </td>";
        html += "</tr>";
        html += "<tr>";
        html += "   <td style='text-align: left; padding: 0 20px 20px 20px;'>";
        html += `       <h5 style='font-size: 20px;font-weight: 700;color: #111827;font-family: Roboto; margin: 0px;'>Your visit request has been deny:</h5>`;
        html += "   </td>";
        html += "</tr>";
        html += "<tr>";
        html += "   <td style='text-align: left; padding: 0 20px 5px 20px;'>";
        html += `       <p style='font-size: 16px;font-weight: 400;color: #111827;font-family: Roboto; margin: 0px;'>Reason: ${selectReasonText}</p>`;
        html += "   </td>";
        html += "</tr>";
        html += "<tr>";
        html += "   <td style='text-align: left; padding: 0 20px 20px 20px;'>";
        html += `       <p style='font-size: 16px;font-weight: 400;color: #111827;font-family: Roboto; margin: 0px;'>${reasonMessage != "" ? "Message:" : ""} ${reasonMessage}</p>`;
        html += "   </td>";
        html += "</tr>";
        html += "<tr>";
        html += "   <td style='text-align: start; padding: 0 20px 10px 20px;'>";
        html += `       <p style='font-size: 14px;font-weight: 600;color: #111827;font-family: Roboto; text-align: left; margin: 0;'>RESUME OF YOUR VISIT REQUEST</p>`;
        html += "   </td>";
        html += "</tr>";
        html += "<tr>";
        html += "   <td style='text-align: center; padding: 0px 20px 30px 20px;'>";
        html += "       <table width='100%' cellspacing='0' cellpadding='0' border='0' style='border: 1px solid gray; background-color: white; border-radius: 16px; margin: 0px;'>";
        html += "        <tbody>";
        html += "           <tr>";
        html += "               <td style='padding: 20px;'>";
        html += `                   <h5 style='font-family: Roboto; font-size: 16px; color: #111827; text-align: left; margin: 0px;'>Date</h5>`;
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px 20px 20px;'>";
        html += "                   <p style='font-family: Roboto; font-size: 14px; color: #111827; text-align: left; margin: 0px;'>" + formatDateTimeLanguageSpecific(contactLanguage, new Date(whiseCalendarEvents[0].startDateTime)) + "</p>";
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px 20px 20px;'>";
        html += "                   <hr>";
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px 20px 20px;'>";
        html += `                   <h5 style='font-family: Roboto; font-size: 16px; color: #111827; text-align: left; margin: 0px;'>Property</h5>`;
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px;'>";
        html += "                   <div style='float: left;'>";
        if (estate.pictures != undefined && estate.pictures.length > 0 && estate.pictures[0].urlLarge != undefined) {
            html += "                   <img src='" + estate.pictures[0].urlLarge + "' className='rounded-4' width='170' alt='house image' />";
        }
        html += "                   </div>";
        html += "                   <div style='float: left; padding-left: 20px'>";
        if (estate.name != undefined) {
            html += "                   <h5 style='font-family: Roboto; font-size: 16px; color: #111827; text-align: left; margin: 0 0 16px 0;'> " + estate.name + " </h5>";
        } else {
            html += " ";
        }
        html += "                       <p style='font-family: Roboto; font-size: 16px; color: #111827; text-align: left; margin: 0 0 16px 0; vertical-align: text-top;'>";
        html += "                           <img src='" + window.location.origin + "/assets/location-pin.png' width='15' style='vertical-align: text-top;' />";
        html += "                           &nbsp;" + (estate.address == undefined ? "" : estate.address) + " " + (estate.number == undefined ? "" : estate.number) + ", " + (estate.zip == undefined ? "" : estate.zip) + " " + (estate.city == undefined ? "" : estate.city);
        html += "                       </p>";
        html += "                       <p style='font-family: Roboto; font-size: 16px; color: #111827; text-align: left; margin: 0 0 16px 0;vertical-align: middle;'>";
        html += "                           <img src='" + window.location.origin + "/assets/bed-duo.png' width='20' style='vertical-align: middle;' />";
        html += `                           <span style='vertical-align: middle;'> &nbsp; </span> <span style='vertical-align: middle;'>${estate.bedRooms == undefined ? "" : estate.bedRooms} </span>`;
        html += "                           <span style='vertical-align: middle;'> &nbsp; </span> <span style='vertical-align: middle;'> | </span> <span style='vertical-align: middle;'> &nbsp; </span>";
        html += "                           <img src='" + window.location.origin + "/assets/shower-duo.png' width='20' style='vertical-align: middle;' />";
        html += `                           <span style='vertical-align: middle;'> &nbsp; </span> <span style='vertical-align: middle;'>${estate.bathRooms == undefined ? "" : estate.bathRooms}</span>`;
        html += "                           <span style='vertical-align: middle;'> &nbsp; </span> <span style='vertical-align: middle;'> | </span> <span style='vertical-align: middle;'> &nbsp; </span>";
        html += "                           <img src='" + window.location.origin + "/assets/lift-duo.png' width='20' style='vertical-align: middle;' />";
        html += `                           <span style='vertical-align: middle;'> &nbsp; </span> <span style='vertical-align: middle;'>${estate.area == undefined ? "" : estate.area}</span>`;
        html += "                       </p>";
        html += "                   </div>";
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 20px 20px;'>";
        html += "                   <hr>";
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px 20px 20px;'>";
        html += `                   <h5 style='font-family: Roboto; font-size: 16px; color: #111827; text-align: left; margin: 0px;'>Contacts</h5>`;
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px 5px 20px;'>";
        html += "                   <p style='font-family: Roboto; font-size: 14px; color: #111827; margin: 0px; text-align: left;'>" + whiseCalendarEvents[0].contacts[0].firstName + " " + whiseCalendarEvents[0].contacts[0].name + "</p>";
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0 20px 5px 20px;'>";
        html += "                   <p style='font-family: Roboto; font-size: 14px; color: #111827; text-align: left; margin: 0px;'>" + whiseCalendarEvents[0].contacts[0].privateEmail + "</p>";
        html += "               </td>";
        html += "           </tr>";
        html += "           <tr>";
        html += "               <td style='padding: 0px 20px 20px 20px;'>";
        html += "                   <p style='font-family: Roboto; font-size: 14px; color: #111827; text-align: left; margin: 0;'>" + whiseCalendarEvents[0].contacts[0].privateMobile + "</p>";
        html += "               </td>";
        html += "           </tr>";
        html += "        </tbody>";
        html += "       </table>";
        html += "   </td>";
        html += "</tr>";
        html += "</table>";

        //let html = "<div style='border: 1px solid gray; background-color: white; border-radius: 16px; padding: 20px; width: 80%; margin: 0 auto; text-align: left'>";
        //html += "   <div>";
        //if (whiseClientSettings != null && whiseClientSettings.logoUrl != undefined) {
        //    html += "   <img src='" + whiseClientSettings.logoUrl + "' width='200' style='margin: 0 auto; margin-bottom: 1.5rem;' />";
        //} else {
        //    html += "   <img src='" + window.location.origin + "assets/logo-placeholder.png' width='200' style='margin: 0 auto; margin-bottom: 1.5rem;' />";
        //}
        //html += "   </div > ";
        //html += "       <h5 style='font-size: 24px;font-weight: 500;color: #111827;font-family: Poppins; margin: 0; margin-bottom: 1rem'> Your visit request has been denied </h5>";
        //html += `       <p style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'> <span style='font-weight: 500;'>Reason: </span>${selectReasonText} </p>`;
        //html += `       <p style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'> <span style='font-weight: 500;'>${reasonMessage != "" ? "Message:" : ""} </span>${reasonMessage} </p>`;
        //html += "</div> ";

        let subject = `Your request for the viewing of the property ${estate.id} has been deny`;

        let objEmail = {
            emailTo: contactEmail,
            body: html,
            subject: subject,
            emailType: "contact"
        }

        const url = variables.API_URL + `Email/SendEmail?`;
        axios.post(url, JSON.stringify(objEmail), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;
                if (selectAction == "slotManageOption1") {
                    revertWhiseEventToFreeSlot();
                }
                if (selectAction == "slotManageOption2") {
                    DeleteWhiseCalenerEvent();
                }
                if (selectAction == "slotManageOption3") {
                    if (clientId != undefined && Object.keys(estate).length > 0) {
                        deleteEstateAllFreeSlots();
                        DeleteWhiseCalenerEvent();
                    }
                }
                //resetAllFields();
                //setFormSubmit(true);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function formatDateTimeLanguageSpecific(locale, dateStr) {
        const timeZones = {
            "en-US": "America/New_York",
            "en-GB": "Europe/London",
            "fr-FR": "Europe/Paris",
            "fr-BE": "Europe/Brussels",
            "nl-BE": "Europe/Brussels",
            "de-DE": "Europe/Berlin",
            "es-ES": "Europe/Madrid",
            "zh-CN": "Asia/Shanghai",
            "ja-JP": "Asia/Tokyo",
            "hi-IN": "Asia/Kolkata",
            "ru-RU": "Europe/Moscow",
            "ar-SA": "Asia/Riyadh",
            "pt-BR": "America/Sao_Paulo",
            "en-AU": "Australia/Sydney",
            "en-PK": "Asia/Karachi"
        }
        const eventDate = new Date(dateStr);

        let clientLanguage = "en-GB";
        if (whiseClientSettings != null && whiseClientSettings.defaultLanguageId != undefined) {
            clientLanguage = whiseClientSettings.defaultLanguageId;
        }

        const formatter = new Intl.DateTimeFormat(locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,  // Change to true if you need 12-hour format
            timeZoneName: "long", // Change to true if you need 12-hour format
            timeZone: timeZones[clientLanguage], // Specify the desired timezone
            timeZoneName: "long"
        });

        return formatter.format(eventDate);
    }

    const revertWhiseEventToFreeSlot = () => {
        setButtonDisabled(true);
        let url = "https://api.whise.eu/v1/calendars/delete";
        let headers = {
            'Authorization': `Bearer ${clientToken}`,
            'Content-Type': 'application/json'
        };
        let body = {
            "CalendarIds": [whiseCalendarEvents[0].id]
        };
        axios.delete(url, { data: body, headers: headers })
            .then(response => {
                if (response.data) {
                    let address = "";
                    address += estate.address == undefined ? "" : estate.address + " ";
                    address += estate.number == undefined ? "" : estate.number + ", ";
                    address += estate.zip == undefined ? "" : estate.zip;
                    address += estate.city == undefined ? "" : " " + estate.city;

                    let freeSlot = getFreeSlotIds(estate.purpose.id);

                    let action = calendarActions.filter(item => {
                        return item.id == freeSlot;
                    })

                    let subject = action[0].name + " " + address;

                    let configration = {
                        "StartDateTime": whiseCalendarEvents[0].startDateTime,
                        "EndDateTime": whiseCalendarEvents[0].endDateTime,
                        "AllDay": "false",
                        "Private": false,
                        "subject": subject,
                        "UserIds": [whiseCalendarEvents[0].users[0].userId],
                        "EstateIds": [estate.id],
                        "ContactIds": [dummyContactIdForFreeSlot]
                    }
                    configration["CalendarActionId"] = freeSlot;

                    if (whiseCalendarEvents[0].pattern) {
                        configration["Pattern"] = whiseCalendarEvents[0].pattern;
                        configration["PatternEndDate"] = whiseCalendarEvents[0].patternEndDate;
                    }

                    addWhiseCalenderEvent(configration);
                }
            })
            .catch(error => {
                console.log("error", error);
                setButtonDisabled(false);
            });
    }

    const getFreeSlotIds = (purposeId) => {
        if (freeSlotIds != undefined) {
            if (purposeId == 1) {
                return freeSlotIds[0];
            }
            else if (purposeId == 2) {
                return freeSlotIds[1];
            }
        }
    }

    const addWhiseCalenderEvent = async (body) => {
        let url = "https://api.whise.eu/v1/calendars/upsert";
        //let url = "https://api.whise.eu/v1/calendars/update";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, body, config)
            .then(response => {
                console.log(response.data);
                if (response.data) {
                    resetAllFields();
                    setFormSubmit(true);
                    setButtonDisabled(false);
                }
            })
            .catch(error => {
                console.log("error", error)
                setButtonDisabled(true);
            });
    }

    const onChangeRegionDropdown = (event) => {
        let value = event.target.value;
        const selectOption = event.target.selectedOptions[0].text;
        if (value == "7") {
            setTextareaEnable(false);
        } else {
            setTextareaEnable(true);
        }
        setSelectReasonText(selectOption);
        setSelectReason(value);
        setRegionEmpty(false);
    }

    const onChangeActionDropdown = (event) => {
        let value = event.target.id;
        setSelectAction(value);
        setActionEmpty(false);
    }

    const onChangeMessage = (event) => {
        let value = event.target.value;
        setReasonMessage(value);
        setMessageEmpty(false);
    }

    const resetAllFields = () => {
        setSelectReason("");
        setSelectAction("");
        setReasonMessage("");
    }

    const closePage = () => {
        window.close();
    }

    return (
        <>
            <div className="px-4">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="shadow p-4 rounded my-3 bg-white">

                            {
                                formSubmit ?
                                    <>
                                        <div className="text-center mb-3">
                                            <img src={successImg} />
                                        </div>
                                        <div className="text-center">
                                            <p className="form-title mb-3 text-center">Success</p>
                                            <p className="font-size14">An email with the reason for denial has been sent to the candidate.</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button className="site-btn-padd close-page-btn w-50" onClick={closePage}>Close this page</button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <h5 className="page-heading mb-4">Specify Reason for Denial</h5>
                                        <div>
                                            {
                                                whiseCalendarEvents.map((item) => {
                                                    let inputDate = item.startDateTime;
                                                    let dateObj = new Date(inputDate);
                                                    let options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
                                                    let formattedDate = new Intl.DateTimeFormat('en-GB', options).format(dateObj);

                                                    let startDateTime = item.startDateTime;
                                                    let endDateTime = item.endDateTime;

                                                    let startDate = new Date(startDateTime);
                                                    let endDate = new Date(endDateTime);

                                                    // Extract hours and minutes for the start and end times
                                                    let startHours = startDate.getHours();
                                                    let startMinutes = startDate.getMinutes();
                                                    let endHours = endDate.getHours();
                                                    let endMinutes = endDate.getMinutes();

                                                    // Format minutes to always show two digits
                                                    startMinutes = startMinutes.toString().padStart(2, "0");
                                                    endMinutes = endMinutes.toString().padStart(2, "0");

                                                    return (
                                                        <div className="bg-slot-time d-flex justify-content-around align-items-center rounded text-white py-2 mb-4">
                                                            <p className="d-flex mb-0"><img src={dateIcon} className="me-1" /> <span>{formattedDate != null ? formattedDate : "Event Date"}</span>  </p>
                                                            <p className="border-end mb-0"><span className="text-hide">text</span></p>
                                                            <p className="d-flex mb-0"><img src={timeIcon} className="me-1" /> <span>{`${startHours}:${startMinutes} - ${endHours}:${endMinutes}`}</span> </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            {
                                                property != null ?
                                                    property.map((item) => {
                                                        return (
                                                            <div>
                                                                <div className="form-container form-container-expanded room-info mb-4">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-6">
                                                                            <div className="modal-img-container bg-black bor w-100">
                                                                                {
                                                                                    item != undefined && item.pictures != undefined && item.pictures.length > 0
                                                                                        ?
                                                                                        <img src={item.pictures[0].urlLarge} className="property-img" />
                                                                                        :
                                                                                        <img src={placeholderLarge} className="property-img" />

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <p className="mb-0 denail-property-name">
                                                                                {
                                                                                    item.name != null ? item.name : "Property Name"
                                                                                }
                                                                            </p>
                                                                            <div className="denail-property-address d-flex align-items-center mt-2 mb-4">
                                                                                <img src={locationPin} className="small-img-modal me-2" />
                                                                                {
                                                                                    <p className="mb-0">
                                                                                        {
                                                                                            (item.address == undefined ? "" : item.address) + " " +
                                                                                            (item.number == undefined ? "" : item.number) + ", " +
                                                                                            (item.zip == undefined ? "" : item.zip) + " " +
                                                                                            (item.city == undefined ? "" : item.city)
                                                                                        }
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                            <div className="d-flex justify-content-between">
                                                                                <p className="d-flex align-items-center mb-0">
                                                                                    <img src={bedIcon} className={`${property.bedRooms ? "me-2" : ""}`} />
                                                                                    {
                                                                                        item != null && item.bedRooms ?

                                                                                            <span className="smallImgIcon-Modal-info">{item.bedRooms}</span>
                                                                                            :
                                                                                            <span className="smallImgIcon-Modal-info"></span>
                                                                                    }
                                                                                </p>
                                                                                <span className="border-end"></span>
                                                                                <p className="d-flex align-items-center mb-0">
                                                                                    <img src={showerIcon} className={`${item.bathRooms ? "me-2" : ""}`} />
                                                                                    {
                                                                                        item != null && item.bathRooms ?
                                                                                            <span className="smallImgIcon-Modal-info">{item.bathRooms}0</span>
                                                                                            :
                                                                                            <span className="smallImgIcon-Modal-info"></span>
                                                                                    }
                                                                                </p>
                                                                                <span className="border-end"></span>
                                                                                <p className="d-flex align-items-center mb-0">
                                                                                    <img src={liftIcon} className={`${item.area ? "me-2" : ""}`} />
                                                                                    {
                                                                                        item != null && item.area ?
                                                                                            <span className="smallImgIcon-Modal-info">{item.area}m<sup>2</sup></span>
                                                                                            :
                                                                                            <span className="smallImgIcon-Modal-info"></span>
                                                                                    }
                                                                                </p>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                    :

                                                    <></>
                                            }
                                        </div>

                                        <div className="mb-4">
                                            <p className="text-red font-size14 mb-0">Please select a reason for denying the booking request, and choose what to do with the associated time slot.</p>
                                        </div>
                                        <div className="mb-3">
                                            <p className="form-title mb-1">Denial Reason<span className="text-danger">*</span></p>
                                            <select className={`form-select ${regionEmpty ? "border-danger" : ""} `} value={selectReason} onChange={onChangeRegionDropdown}>
                                                <option value="">Please select the region</option>
                                                <option value="1">Agenda is full</option>
                                                <option value="2">Property doesn't fit your search criteria</option>
                                                <option value="3">Visit are temporaly suspended</option>
                                                <option value="4">Property is under offer</option>
                                                <option value="5">Property is sold</option>
                                                <option value="6">Property is rented</option>
                                                <option value="7">Others reason (manuel entry)</option>
                                            </select>
                                            <span className="text-red fs-small login-field-error">{regionEmpty ? "Please fill the required fields" : ""} </span>
                                        </div>
                                        <div className="mb-4">
                                            <p className="form-title mb-1">Message<span className="text-danger">*</span></p>
                                            <textarea className="form-control ques-desc-area mb-1" value={reasonMessage} onChange={onChangeMessage} disabled={textareaEnable}>
                                            </textarea>
                                            <p className="mb-0 question-feild-bottom-text d-flex justify-content-between"><span className="text-red fs-small login-field-error">{messageEmpty && textareaEnable == false ? "Please fill the required fields" : ""} </span> <span> <span className="text-green">600</span> Caractères restants </span></p>
                                        </div>
                                        <hr />
                                        <div className="mt-4 mb-5">
                                            <p className="form-title mb-1">Slot Management Options<span className="text-danger">*</span></p>
                                            <p className="form-heading mb-1">Briefly describe the impact of each choice</p>
                                            <div>
                                                <div className="mb-2">
                                                    <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-100 line-height-0" htmlFor="slotManageOption1" >
                                                        <input className="custom-radio me-2" type="radio" name="slotMangeOption" id="slotManageOption1" checked={selectAction === "slotManageOption1"} onChange={onChangeActionDropdown} />
                                                        Make this slot available again
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <label className={`modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-100 line-height-0`} htmlFor="slotManageOption2">
                                                        <input className="custom-radio me-2" type="radio" name="slotMangeOption" id="slotManageOption2" checked={selectAction === "slotManageOption2"} onChange={onChangeActionDropdown} />
                                                        Delete this slot
                                                    </label>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-100 line-height-0" htmlFor="slotManageOption3">
                                                        <input className="custom-radio me-2" type="radio" name="slotMangeOption" id="slotManageOption3" checked={selectAction === "slotManageOption3"} onChange={onChangeActionDropdown} />
                                                        Delete this slot and all other slots
                                                    </label>
                                                </div>
                                                <span className="text-red fs-small login-field-error">{actionEmpty ? "Please fill the required fields" : ""} </span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {/*<button className="filter-btn site-btn-padd border-dark color-dark w-100 me-3">Cancel</button>*/}
                                            <button className="site-btn site-btn-padd w-50" onClick={emailSendToContact} disabled={buttonDisabled}>Validate & Send</button>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        </>
    )

}








