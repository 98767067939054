import * as React from "react";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { seteventdetail, cleareventdetail } from "../features/CreateEventDetailSlice";

import plusBlueIcon from '../assets/images/plus_blue.svg';
import trashIcon from '../assets/images/trash.svg';
import closeIcon from '../assets/images/cross-close.svg';
import warning from '../assets/images/warning.svg';

const { useState, useEffect } = React;

export const AvailableHours = ({ handleClose, callBackClickHandlerNext, backButtonClickHandler }) => {
    const [checkbox, setCheckbox] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);
    const [timeSlotValidationMessage, setTimeSlotValidationMessage] = useState("");

    //const [timeSlots, setTimeSlots] = useState([
    //    { day: "Mon", weekDay: 1, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false },
    //    { day: "Tue", weekDay: 2, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false },
    //    { day: "Wed", weekDay: 3, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false },
    //    { day: "Thur", weekDay: 4, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false },
    //    { day: "Fri", weekDay: 5, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false },
    //    { day: "Sat", weekDay: 6, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false },
    //    { day: "Sun", weekDay: 7, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false }
    //])

    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

    const [validTill, setValidTill] = useState("");
    const status = useSelector(state => state.statusFilter.statusFilter);
    const agent = useSelector(state => state.agentFilter.agentFilter);
    const reduxEventDetail = useSelector(state => state.eventDetail.eventDetail);
    const dispatch = useDispatch();

    const addNewSlotDurationRow = (e) => {
        var rowData = JSON.parse(e.target.getAttribute("durationitem"));
        let slotDuration = +reduxEventDetail.event.EventDuration;

        if (rowData.isChecked == false) {
            return;
        }
        let updatedTimeSlots = timeSlots.map(item => {
            if (rowData.day == item.day && item.endTimes.length > 0) {

                //get last element of start time and end time of current duration row
                let lastElementEndTimes = item.endTimes[item.endTimes.length - 1];
                let length = item.endTimes.length;

                //convert duration to milliseconds
                let minutesToAdd = slotDuration * 60000;

                //get last row start and end time of current duration row
                item.startTimes.push({ field: item.day + 'From' + length + 1, date: lastElementEndTimes.date });

                //add minutes
                let endDateToSet = new Date(lastElementEndTimes.date);
                endDateToSet.setTime(endDateToSet.getTime() + minutesToAdd);

                //push final element to start and end times arrays of selected duration row
                item.endTimes.push({ field: item.day + 'To' + length + 1, date: endDateToSet });
                item.durationSlotsRows.push(item.durationSlotsRows.length + 1);
            }
            return item
        })
        setTimeSlots(updatedTimeSlots);
    }

    const deleteSlotRow = (e) => {
        var rowToDelete = JSON.parse(e.target.getAttribute("rownumber"));
        var rowData = JSON.parse(e.target.getAttribute("durationitem"));
        let updatedTimeSlots = timeSlots.map(item => {
            if (rowData.day == item.day) {
                let indexOfRow = item.durationSlotsRows.indexOf(+rowToDelete);
                item.startTimes.splice(indexOfRow, 1);
                item.endTimes.splice(indexOfRow, 1);
                item.durationSlotsRows.splice(indexOfRow, 1);
            }
            return item
        })
        setTimeSlots(updatedTimeSlots);
    }

    const enableSelectedDayTimeSlot = (e) => {
        let checkboxValue = e.target.checked;
        var rowData = JSON.parse(e.target.getAttribute("durationitem"));
        let updatedTimeSlots = timeSlots.map(item => {
            if (rowData.day == item.day) {
                if (checkboxValue == true) {
                    item.isChecked = true;
                }
                else {
                    item.isChecked = false;
                }
            }
            return item
        })
        setTimeSlots(updatedTimeSlots);
    }

    const setStartTime = (e) => {
        let time = e.target.value;
        var rowData = JSON.parse(e.target.getAttribute("durationitem"));
        let elementId = rowData.day + "To" + rowData.durationSlotsRows.length;

        if (e.target.value != "") {
            e.target.removeAttribute("style");
            setTimeSlotValidationMessage("");
        }
        let splittedTime = time.split(":");
        let timeArray = splittedTime.map(item => { return +item });

        let date = new Date();
        var currentDay = date.getDay();
        let currentHour = date.getHours();
        let currentMinutes = date.getMinutes();

        //if (rowData.weekDay == currentDay && timeArray[0] < currentHour && (validTill == "" || validTill == "1")) {
        //    e.target.value = "";
        //    alert("selected time is in past! please choose time greater than current time");
        //    return;
        //}

        //if (rowData.weekDay == currentDay && timeArray[0] == currentHour && timeArray[1] < currentMinutes && (validTill == "" || validTill == "1")) {
        //    e.target.value = "";
        //    alert("selected time is in past! please choose time greater than current time");
        //    return;
        //}

        //if (rowData.weekDay == currentDay && validTill != "" && validTill != "1") {
        //    if (timeArray[0] < currentHour && timeArray[1] < currentMinutes) {
        //        rowData.weekDay += 7;
        //    }
        //    if (timeArray[0] == currentHour && timeArray[1] < currentMinutes) {
        //        rowData.weekDay += 7;
        //    }
        //}
        //if (rowData.weekDay < currentDay && validTill != "" && validTill != "1") {
        //    rowData.weekDay += 7;
        //}

        var distance = rowData.weekDay - currentDay;
        if (distance < 0) {
            date.setDate(date.getDate() + distance + 7);
        }
        else {
            date.setDate(date.getDate() + distance);
        }
        date.setHours(timeArray[0]);
        date.setMinutes(timeArray[1]);

        let updatedTimeSlots = timeSlots.map(item => {
            if (rowData.day == item.day) {

                let indexExist = -1;
                item.startTimes.forEach((item, i) => {
                    if (item.field == e.target.id) {
                        indexExist = i;
                    }
                })
                let timeObj = { field: e.target.id, date: date }
                if (indexExist == -1) {
                    item.startTimes.push(timeObj);
                }
                else {
                    item.startTimes[indexExist] = timeObj
                }
            }
            return item
        })
        setTimeSlots(updatedTimeSlots);
        setEndTime(elementId, date, rowData)
    }

    const setEndTime = (elementId, date, rowData) => {
        //let time = e.target.value;
        //var rowData = JSON.parse(e.target.getAttribute("durationitem"));
        document.getElementById(elementId).removeAttribute("style");

        //let splittedTime = time.split(":");
        //let timeArray = splittedTime.map(item => { return +item });

        //let date = new Date();
        //var currentDay = date.getDay();
        //var distance = rowData.weekDay - currentDay;
        //date.setDate(date.getDate() + distance);
        //date.setHours(timeArray[0]);
        //date.setMinutes(timeArray[1]);

        let endDate = new Date(date);
        let minutesToAdd = +reduxEventDetail.event.EventDuration
        endDate.setMinutes(endDate.getMinutes() + minutesToAdd);
        let hours = endDate.getHours() < 10 ? "0" + endDate.getHours() : endDate.getHours();
        let minutes = endDate.getMinutes() < 10 ? "0" + endDate.getMinutes() : endDate.getMinutes();
        var time = hours + ":" + minutes;
        document.getElementById(elementId).value = time;

        let updatedTimeSlots = timeSlots.map(item => {
            if (rowData.day == item.day) {

                let indexExist = -1;
                item.endTimes.forEach((item, i) => {
                    if (item.field == elementId) {
                        indexExist = i;
                    }
                })
                let timeObj = { field: elementId, date: endDate }
                if (indexExist == -1) {
                    item.endTimes.push(timeObj);
                }
                else {
                    item.endTimes[indexExist] = timeObj
                }
            }
            return item
        })
        setTimeSlots(updatedTimeSlots);
    }

    const changeHandlerEndTime = (e) => {
        setTimeSlotValidationMessage("");
        let endTime = e.target.value;
        let slotDuration = +reduxEventDetail.event.EventDuration;
        var rowData = JSON.parse(e.target.getAttribute("durationitem"));
        let fromElementId = rowData.day + "From" + 1;
        let fromTime = document.getElementById(fromElementId).value;

        if (fromTime == "") {
            setTimeSlotValidationMessage("Select event start time first");
            e.target.value = "";
            return;
        }

        let startDateTime = new Date();
        let endDateTime = new Date();

        startDateTime.setHours(fromTime.split(":")[0]);
        startDateTime.setMinutes(fromTime.split(":")[1]);
        endDateTime.setHours(endTime.split(":")[0]);
        endDateTime.setMinutes(endTime.split(":")[1]);

        let startingAt = fromTime.split(":")[0];
        let endingAt = endTime.split(":")[0];
        if (endingAt < startingAt) {
            setTimeSlotValidationMessage("Event end time should be greater than start time");
            return;
        }

        var diffMs = (endDateTime - startDateTime); // milliseconds between start and end date
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        if (slotDuration == 60 && diffMins != 0) {
            setTimeSlotValidationMessage("Please choose a start hour and end hour that can be divide in slot of '60 minutes' or go back and change the selected duration choosen in first screen.");
            return;
        }
        let isValidEndTime = false;
        let slotsToAdd = 0;
        if (slotDuration < 60) {
            let totalMinutes = diffHrs * 60;
            totalMinutes += diffMins;
            let remainder = totalMinutes % slotDuration;
            if (remainder != 0) {
                setTimeSlotValidationMessage("Please choose a start hour and end hour that can be divide in slot of '" + slotDuration + " minutes' or go back and change the selected duration choosen in first screen");
                return;
            }
            else {
                isValidEndTime = true;
                slotsToAdd = totalMinutes / slotDuration;
            }
        }

        timeSlots.forEach(item => {
            if (rowData.day == item.day) {
                let firstStartIem = item.startTimes[0];
                let firstEndItem = item.endTimes[0];
                item.startTimes = [];
                item.startTimes.push(firstStartIem);
                item.endTimes = [];
                item.endTimes.push(firstEndItem);
                item.durationSlotsRows = [1];
            }
        })

        let hour = "";
        let minutes = "";

        if (slotDuration == 60 && diffHrs > 0) {
            let updatedTimeSlots = timeSlots.map(item => {
                if (rowData.day == item.day) {
                    for (var i = 0; i < diffHrs; i++) {
                        if (i == 0) {
                            let eventEndTime = new Date(item.startTimes[i].date);
                            eventEndTime.setHours(eventEndTime.getHours() + 1);
                            item.endTimes[i].date = eventEndTime;
                            hour = eventEndTime.getHours();
                            minutes = eventEndTime.getMinutes();
                        }
                        else {
                            let startTime = new Date(item.endTimes[i-1].date);
                            item.startTimes.push({ field: item.day + 'From' + (i + 1), date: startTime })
                            let endTime = new Date(startTime);
                            endTime.setHours(endTime.getHours() + 1);
                            item.endTimes.push({ field: item.day + 'To' + i, date: endTime });
                            item.durationSlotsRows.push(item.durationSlotsRows.length + 1);
                        }
                    }
                }
                return item
            })
            setTimeSlots(updatedTimeSlots);
        }
        if (slotDuration < 60 && isValidEndTime == true) {
            let minutesToAdd = slotDuration * 60000;
            let updatedTimeSlots = timeSlots.map(item => {
                if (rowData.day == item.day) {
                    for (var i = 0; i < slotsToAdd; i++) {
                        if (i == 0) {
                            let eventEndTime = new Date(item.startTimes[i].date);
                            eventEndTime.setTime(eventEndTime.getTime() + minutesToAdd);
                            item.endTimes[i].date = eventEndTime;
                            hour = eventEndTime.getHours();
                            minutes = eventEndTime.getMinutes();
                        }
                        else {
                            let startTime = new Date(item.endTimes[i - 1].date);
                            item.startTimes.push({ field: item.day + 'From' + (i + 1), date: startTime })
                            let endTime = new Date(startTime);
                            endTime.setTime(endTime.getTime() + minutesToAdd);
                            item.endTimes.push({ field: item.day + 'To' + i, date: endTime })
                            item.durationSlotsRows.push(item.durationSlotsRows.length + 1);
                        }
                    }
                }
                return item
            })
            setTimeSlots(updatedTimeSlots);
        }
        e.target.value = (hour < 10 ? "0" + hour : hour) + ":" + (minutes < 10 ? "0" + minutes : minutes);

    }

    const clickHandlerNext = () => {
        //let updatedSlots = [];
        //let isAnySlotSet = false;
        //if (validTill == "" || validTill == "1") {
        //    let slots = [...timeSlots];
        //    let currentDate = new Date();

        //    updatedSlots = slots.map(item => {
        //        let startDate = item.startTimes.length > 0 ? item.startTimes[0].date : "";
        //        if (startDate != "" && startDate.getDay() >= currentDate.getDay()) {
        //            isAnySlotSet = true;
        //            return item;
        //        }
        //        else if (startDate != "" && startDate.getDay() < currentDate.getDay()) {
        //            item.durationSlotsRows = [1];
        //            item.startTimes = [];
        //            item.endTimes = [];
        //            item.isChecked = false;
        //            return item;
        //        }
        //        else {
        //            item.durationSlotsRows = [1];
        //            item.startTimes = [];
        //            item.endTimes = [];
        //            if (item.isChecked == true) {
        //                item.isChecked = true;
        //                isAnySlotSet = true;
        //            }
        //            else {
        //                item.isChecked = false;
        //            }
        //            return item;
        //        }
        //    })
        //    setTimeSlots(updatedSlots);
        //    if (isAnySlotSet == false) {
        //        alert("please select a slot first");
        //        return;
        //    }
        //}

        let isSelectedFieldsEmpty = false;
        let eventsStartDates = [];
        let eventsEndDates = [];

        let anySlotChecked = timeSlots.some(item => item.isChecked == true);

        timeSlots.forEach(item => {
            if (item.isChecked) {
                item.durationSlotsRows.forEach(d => {
                    let fromField = document.querySelector("#" + item.day + "From" + d);
                    let toField = document.querySelector("#" + item.day + "To" + d);
                    if (fromField.value == "") {
                        fromField.style.borderColor = "red";
                        isSelectedFieldsEmpty = true;
                    }
                    if (toField.value == "") {
                        toField.style.borderColor = "red";
                        isSelectedFieldsEmpty = true;
                    }
                })
                item.startTimes.forEach(time => {
                    eventsStartDates.push({ day: item.day, date: time.date });
                    if (validTill != "") {
                        for (var i = 1; i < validTill; i++) {
                            let date = new Date(time.date);
                            date.setDate(date.getDate() + (i * 7));
                            eventsStartDates.push({ day: item.day, date: date });
                        }
                    }
                })
                item.endTimes.forEach(time => {
                    eventsEndDates.push({ day: item.day, date: time.date });
                    if (validTill != "") {
                        for (var i = 1; i < validTill; i++) {
                            let date = new Date(time.date);
                            date.setDate(date.getDate() + (i * 7));
                            eventsEndDates.push({ day: item.day, date: date });
                        }
                    }
                })
            }
        })
        if (isSelectedFieldsEmpty == true) {
            setTimeSlotValidationMessage("Please filled the selected fields");
            //alert("please filled the selected fields");
            return;
        }
        if (anySlotChecked == false) {
            setTimeSlotValidationMessage("Please choose at least one slot");
            //alert("please filled the selected fields");
            return;
        }

        let slotsToSave = [];

        slotsToSave = eventsStartDates.map((item, i) => {
            let hoursDetail = {};
            hoursDetail.AllDay = false;
            hoursDetail.Day = item.day;
            var date = new Date(item.date)
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            hoursDetail.StartDateTime = date.toISOString();
            return hoursDetail;
        })

        eventsEndDates.forEach((item, i) => {
            if (i <= slotsToSave.length) {
                var date = new Date(item.date)
                date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                slotsToSave[i].EndDateTime = date.toISOString();
            }
        })



        let filteredSlots = slotsToSave.filter(item => {
            let currentdate = new Date();
            let eventDate = new Date(item.StartDateTime);
            eventDate.setMinutes(eventDate.getMinutes() + eventDate.getTimezoneOffset());

            return eventDate > currentdate
        })

        let eventDetail = {
            event: reduxEventDetail.event,
            availableHours: filteredSlots,
            invitee: null,
            confirmEventSlot: null
        };
        dispatch(seteventdetail(eventDetail));
        callBackClickHandlerNext();
    }

    const setSlotValidity = (e) => {
        if (e.target.value != "") {
            setValidTill(+e.target.value);
        }
    }

    useEffect(() => {
        let slotArray = [];
        let currentDateTime = new Date();
        for (var i = 0; i < 7; i++) {
            if (i > 0) {
                currentDateTime.setDate(currentDateTime.getDate() + 1);
            }
            let weekDay = currentDateTime.getDay();
            let currentDay = days[currentDateTime.getDay()];

            slotArray.push(
                { day: currentDay, weekDay: weekDay, durationSlotsRows: [1], startTimes: [], endTimes: [], isChecked: false }
            )
        }

        setTimeSlots(slotArray);

    }, [])

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                </ul>
                <img src={closeIcon} onClick={handleClose} className="cursor-pointer" />
            </div>
            <div>
                <p className="form-title mb-2"> New Event </p>
            </div>
            <div className="warning mb-2">
                <img src={warning} className="me-3" />
                <p className="mb-0">
                    Only future events will be catered.
                </p>
            </div>
            <div>
                <p className="subtitle mb-1">Available Hours</p>
                <p className="modal-p-text mb-4">Set the time that people will be  able to schedule these type of meetings with you.</p>
            </div>
            <div className="form-group mb-3">
                <label className="form-group-label mt-0">Valid Till</label>
                <select className="form-select" onChange={setSlotValidity}>
                    <option value="">Select Duration</option>
                    <option value="1">Current Week</option>
                    <option value="2">2 Week</option>
                    <option value="3">3 Week</option>
                    <option value="4">4 Week</option>
                    <option value="5">5 Week</option>
                    <option value="6">6 Week</option>
                    <option value="7">7 Week</option>
                    <option value="8">8 Week</option>
                    <option value="9">9 Week</option>
                    <option value="10">10 Week</option>
                </select>
            </div>
            <div className={`${timeSlotValidationMessage == "" ? "d-none" : "mb-3"}`}>
                <p className={`text-red fs-small login-field-error`}>{timeSlotValidationMessage}</p>
            </div>
            {
                timeSlots.length > 0 ? timeSlots.map((item, index) => {

                    let currentDate = new Date();
                    var currentDay = currentDate.getDay();
                    let isFieldDisabled = false;
                    if (item.weekDay < currentDay && (validTill == "" || validTill == 1)) {
                        isFieldDisabled = true;
                    }

                    let dayname = days[currentDay];

                    return (
                        <div key={`timeSlot-${index}`}>
                            <div className={`form-check d-flex align-items-center ${item.isChecked == true && item.durationSlotsRows.length > 1 ? "mb-4" : ""}`}>

                                <input className="form-check-input available-hours-check me-3 mt-0" type="checkbox" durationitem={JSON.stringify(item)} id={`${item.day}Checkbox${index}`} onChange={enableSelectedDayTimeSlot} />
                                <label className={`day-lbl form-check-label me-3 ${dayname == item.day ? "text-highlighted" : ""}`} htmlFor={`${item.day}Checkbox${index}`}>
                                    {item.day}
                                </label>
                                <input type="time" className={`form-control w-100 me-3 ${item.isChecked == false ? "hidden" : ""}`}
                                    durationitem={JSON.stringify(item)} id={`${item.day}From1`}
                                    onInput={setStartTime} />
                                <input type="time" className={`form-control w-100 me-3 ${item.isChecked == false ? "hidden" : ""}`}
                                    durationitem={JSON.stringify(item)} id={`${item.day}To1`}
                                    onInput={changeHandlerEndTime} />
                                <img src={plusBlueIcon} onClick={addNewSlotDurationRow} durationitem={JSON.stringify(item)} />

                            </div>
                            {
                                item.durationSlotsRows.length > 0 ? item.durationSlotsRows.map((d, i) => {
                                    if (i == 0) {
                                        return (<></>);
                                    }
                                    let startHour = item.startTimes[i].date.getHours();
                                    let startMinutes = item.startTimes[i].date.getMinutes();
                                    let endHour = item.endTimes[i].date.getHours();
                                    let endMinutes = item.endTimes[i].date.getMinutes();

                                    let startTime = (startHour < 10 ? "0" + startHour : startHour) + ":" + (startMinutes < 10 ? "0" + startMinutes : startMinutes);
                                    let endTime = (endHour < 10 ? "0" + endHour : endHour) + ":" + (endMinutes < 10 ? "0" + endMinutes : endMinutes);
                                    return (
                                        <div className="form-check mb-4 d-flex align-items-center" key={`durationSlot-${index}-${i}`}>
                                            <input className="form-check-input available-hours-check me-3 mt-0 hidden" type="checkbox" value="" />
                                            <label className="day-lbl form-check-label me-3 hidden" htmlFor={`${item.day}Checkbox${i}`}>
                                                {item.day}
                                            </label>
                                            <input type="time" className={`form-control w-100 me-3 ${item.isChecked == false ? "hidden" : ""}`}
                                                durationitem={JSON.stringify(item)} id={`${item.day}From${d}`}
                                                onInput={setStartTime} value={startTime} />
                                            <input type="time" className={`form-control w-100 me-3 ${item.isChecked == false ? "hidden" : ""}`}
                                                id={`${item.day}To${d}`} onInput={setEndTime} value={endTime} disabled />
                                            <img src={trashIcon} onClick={deleteSlotRow} durationitem={JSON.stringify(item)} rownumber={d} />
                                        </div>
                                    )
                                })
                                    :
                                    <></>
                            }
                        </div>
                    )
                })
                    :
                    <></>
            }
            <div className="d-flex mt-4">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" onClick={backButtonClickHandler}>Back</button>
                <button type="button" className="site-btn site-btn-padd w-100" onClick={clickHandlerNext}>Next</button>
            </div>
        </>
    )
}