import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setestateconfig, clearestateconfig } from "../features/EstateConfigurationSlice";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import closeIcon from '../assets/images/cross-close.svg';
import settingIcon from '../assets/images/cog-wd-bg.svg';
import fileIcon from '../assets/images/file-icon.svg';
import uploadIcon from '../assets/images/upload-icon.svg';

export const EstateConfigSummary = ({ closeConfigModal, clickHandleMoveBackToPreviousScreen, saveEstateConfigurations, documentsType }) => {
    const reduxEstateConfigs = useSelector(state => state.estateConfiguration.estateConfiguration);
    const questionsLength = reduxEstateConfigs != null ? reduxEstateConfigs.length - 1 : 0;

    console.log(reduxEstateConfigs);

    const handleKeyDown = (e) => {
        // Prevent up and down arrow keys from modifying the value
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                </ul>
                <img src={closeIcon} onClick={closeConfigModal} className="cursor-pointer" />
            </div>
            <div>
                <p className="form-title mb-2"> Setup the booking form </p>
            </div>
            <div>
                <p className="subtitle mb-0">Type of answers</p>
                <p className="modal-p-text-black mb-4">Lorem ipsum dolor sit amet consectetur. Ullamcorper netus sed est lorem. Facilisis dictum bibendum tellus eu eget porttitor. Arcu eget molestie sed consectetur integer. Risus malesuada id lacus egestas. Sit maecenas iaculis nibh eget habitasse ac. Fermentum pellentesque mattis enim adipiscing aliquet. Risus tellus augue lectus sit eu pharetra. Mattis fermentum nibh viverra egestas fames. Ut ut in mattis blandit magna. Laoreet arcu morbi.</p>
            </div>
            <div className="question-summary-container p-3 mb-4">
                <div>
                    <p className="subtitle mb-3">Informations</p>
                </div>
                <div className="form-group mb-4">
                    <label className="form-group-label mt-0">Name</label>
                    <input type="text" id="name" className="form-control w-100" placeholder="Name" readOnly={true} />
                </div>
                <div className="form-group mb-4">
                    <label className="form-group-label mt-0">First Name</label>
                    <input type="text" id="firstName" className="form-control w-100" placeholder="First Name" readOnly={true} />
                </div>
                <div className="form-group mb-4">
                    <label className="form-group-label mt-0">Email</label>
                    <input type="email" id="email" className="form-control w-100" placeholder="Email" readOnly={true} />
                </div>
                <div className="form-group">
                    <label className="form-group-label mt-0">Phone</label>
                    <PhoneInput
                        country={'be'}
                        onlyCountries={['be']}
                        enableAreaCodes={true}
                        countryCodeEditable={false}
                        disabled={false}
                    />
                </div>
            </div>
            <div className="question-summary-container p-3 mb-4">
                <div>
                    <p className="form-title mb-3">Questions</p>
                </div>
                <div>
                    {
                        reduxEstateConfigs && reduxEstateConfigs.length > 0 ?
                            reduxEstateConfigs.map((item, i) => {
                                const questionDetail = JSON.parse(item.questionDetail);
                                let answerOptions = questionDetail.answerOptions;
                                return (
                                    <>
                                        <div className={`${i == questionsLength ? "" : "mb-4"}`} key={item.id} >
                                            <div className="d-flex justify-content-between align-items-baseline">
                                                <p className="subtitle mb-3">{questionDetail["questionTitle-en-GB"]} {item.isMandatory ? <span className='text-red'>*</span> : ""}</p>
                                            </div>
                                            <div className="d-flex">
                                                {
                                                    questionDetail.answerType == 2 || questionDetail.answerType == 3 && answerOptions.length > 0 ?
                                                        answerOptions.map((option) => {
                                                            let label = option[`option${option.id}-en-GB`];
                                                            return (
                                                                <div className="me-2" key={option.id}>
                                                                    <label className="modal-p-text-black asnwer-options-preview d-flex align-items-center mb-2 w-100 me-2 line-height-0" htmlFor={`answerOption${item.id}${option.id}`}>
                                                                        <input className="custom-radio me-2" type="radio" name={`answerOption${item.id}`} id={`answerOption${item.id}${option.id}`} />
                                                                        {label}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        questionDetail.answerType == 1 ?
                                                            <div className="d-flex col-4">
                                                                <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radio${item.id}`}>
                                                                    <input className="custom-radio me-2" type="radio" name={`radio${item.id}`} id={`radio${item.id}`} />
                                                                    Yes
                                                                </label>

                                                                <label className="modal-p-text-black asnwer-options-preview d-flex bg-white align-items-center w-50 line-height-0" htmlFor={`radio${item.id}${questionDetail.answerType}`}>
                                                                    <input className="custom-radio me-2" type="radio" name={`radio${item.id}`} id={`radio${item.id}${questionDetail.answerType}`} />
                                                                    No
                                                                </label>
                                                            </div>
                                                            :
                                                            questionDetail.answerType == 4 || questionDetail.answerType == 5 ?
                                                                <>
                                                                    {
                                                                        questionDetail.answerType == 4 ?
                                                                            <input type="number" className={`form-control bg-white mb-2 no-spinner`}
                                                                                onPaste={(e) => e.preventDefault()} onKeyDown={handleKeyDown}
                                                                            />
                                                                            :
                                                                            <input type="text" className={`form-control bg-white mb-2`} />
                                                                    }

                                                                </>
                                                                :
                                                                <>
                                                                    <input type="date" className="form-control bg-white mb-2" />
                                                                </>
                                                }
                                            </div>
                                        </div>
                                        {
                                            questionsLength != undefined && i == questionsLength ?
                                                <></>
                                                :
                                                <hr />
                                        }
                                    </>
                                )
                            })
                            :
                            ""
                    }
                </div>
            </div>
            <div className="question-summary-container p-3 mb-4">
                <div>
                    <p className="form-title mb-2">Documents</p>
                </div>
                <div>
                    {
                        documentsType && documentsType.length > 0 ?
                            documentsType.map((item, i) => {
                                let length = documentsType.length
                                return (
                                    <>
                                        <div>
                                            <p className="subtitle mb-0">{item.label} {item.isMandatory ? <span className='text-red'>*</span> : ""}</p>
                                            <div className="d-flex justify-content-between align-items-baseline">
                                                <button className="btn btn-upload-file site-btn-padd d-flex justify-content-between align-items-center">
                                                    <span>
                                                        <img src={fileIcon} className="me-2" />
                                                        {
                                                            item.isManualName == true ?
                                                                item.manualDocumentName
                                                                :
                                                                item.documentTypeName
                                                        }
                                                    </span>
                                                    <span>
                                                        <img src={uploadIcon} />
                                                    </span>
                                                </button>
                                                <p className="upload-file-type">Only <span className="text-success">Pdf, doc, txt, jpg, jpeg, png </span>files are allowed. ( Max size : 5Mb )</p>
                                            </div>
                                        </div>
                                        {
                                            i < length - 1 ? <hr /> : <></>
                                        }
                                    </>
                                )
                            })
                            :
                            <></>
                    }
                </div>
            </div>
            <div className="d-flex w-50 mx-auto">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-100 me-3" onClick={clickHandleMoveBackToPreviousScreen}>Back</button>
                <button type="button" className="site-btn site-btn-padd w-100" onClick={saveEstateConfigurations}>Next</button>
            </div>
        </>
    )
}