export const variables = {
    API_URL: "https://smartcalendar-api.mindfree.be/api/", //"https://localhost:7118/api/", //"https://smartcalendar-staging-api.mindfree.be/api/", //
    Booking_URL: "https://smartcalendar-booking.mindfree.be/", //"https://localhost:44438/", //"https://smartcalendar-staging-booking.mindfree.be/", //
    //ContactId: "32065699", //"31829918", //
    WhiseUser: "dev.smart@mindfree.be", //"dev@realadvice.be", //
    WhisePassword: "pa$$worD1"
};

export const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);