import { createSlice } from "@reduxjs/toolkit";

export const LanguageSlice = createSlice({
    name: "allLanguages",
    initialState: {
        languages: [],
    },
    reducers: {
        setlanguages: (state, action) => {
            state.languages = action.payload;
        },
        resetlanguages: (state, action) => {
            state.languages = null;
        }
    }
})


export const { setlanguages, resetlanguages } = LanguageSlice.actions;

export default LanguageSlice.reducer;

