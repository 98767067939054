import { createSlice } from "@reduxjs/toolkit";

export const EstateConfigurationSlice = createSlice({
    name: "estateConfiguration",
    initialState: {
        estateConfiguration: null,
    },
    reducers: {
        setestateconfig: (state, action) => {
            state.estateConfiguration = action.payload;
        },
        clearestateconfig: (state, action) => {
            state.estateConfiguration = null;
        },
        reorderQuestions: (state, action) => {
            const { currentOrder, targetOrder } = action.payload;

            if (state.estateConfiguration) {
                const questions = [...state.estateConfiguration];
                const currentIndex = questions.findIndex(q => q.order === currentOrder);
                const targetIndex = questions.findIndex(q => q.order === targetOrder);

                if (currentIndex !== -1 && targetIndex !== -1) {
                    // Swap the `order` values of the two items
                    [questions[currentIndex].order, questions[targetIndex].order] =
                        [questions[targetIndex].order, questions[currentIndex].order];

                    // Reassign the updated questions back to state
                    state.estateConfiguration = [...questions];
                }
            }

            //const { fromIndex, toIndex } = action.payload;
            //if (state.estateConfiguration) {
            //    const updatedQuestions = [...state.estateConfiguration];

            //    // Swap the `order` values of the two items immutably
            //    [updatedQuestions[fromIndex].order, updatedQuestions[toIndex].order] = [updatedQuestions[toIndex].order, updatedQuestions[fromIndex].order];

            //    // Reassign the updated questions back to state
            //    state.estateConfiguration = updatedQuestions;
            //}
        }
    }
})

export const { setestateconfig, clearestateconfig, reorderQuestions } = EstateConfigurationSlice.actions;

export default EstateConfigurationSlice.reducer;