import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from 'react-bootstrap/Accordion';
import { setquestiondetail, clearquestiondetail } from "../features/QuestionDetailSlice";

import closeIcon from '../assets/images/cross-close.svg';
import plusBlue from '../assets/images/plus_blue.svg';
import deleteIcon from '../assets/images/question-ans-delete-icon.svg';
import { useState } from "react";
import { useEffect } from "react";

export const RequestDocuments = ({ closeRequestDocumentsModal, moveBackToSelectionSummary, moveToConfigSummaryFromDocType, documentsType, setDocumentsType }) => {
    //const [documentsType, setDocumentsType] = useState([]);
    const [requiredManualNameMessage, setRequiredManualNameMessage] = useState("");

    const moveToNextScreen = () => {
        const hasErrors = documentsType.some(item => item.isManualName && !item.manualDocumentName);
        let updatedList = documentsType.map(item => {
            if (item.isManualName == true && item.manualDocumentName == "") {
                return {
                    ...item,
                    hasError: true
                };
            }
            return item;
        })

        setDocumentsType(updatedList);
        if (hasErrors) {
            setRequiredManualNameMessage("Please fill out the required manual document name.");
            return;
        }
        setDocumentsType(updatedList);
        moveToConfigSummaryFromDocType();
    }

    const clickHandleAddMoreDocumentType = (e, docType) => {
        let newId = docType.id + 1;
        let updatedList = documentsType.map(item => {
            if (item.id == docType.id) {
                return {
                    ...item,
                    isDeletable: true
                };
            }
            return item;
        })

        let newObj = {
            id: newId,
            documentTypeId: 1,
            documentTypeName: "ID card",
            label: "Documents " + (newId < 10 ? "0" + newId : newId),
            isManualName: false,
            manualDocumentName: "",
            isDeletable: false,
            isMandatory: false
        }
        updatedList.push(newObj);
        setDocumentsType(updatedList);
    }

    const clickHandleRemoveDocumentTypeRow = (e, docType) => {
        let updatedList = documentsType.filter(item => {
            return item.id != docType.id
        })

        let updatedListWithNewIds = updatedList.map((item, index) => {
            let newId = index + 1;
            return { ...item, id: newId, label: "Documents " + (newId < 10 ? "0" + newId : newId) }; // Reassign ids starting from 1
        });

        const hasErrors = updatedList.some(item => item.isManualName && !item.manualDocumentName);
        if (!hasErrors) {
            setRequiredManualNameMessage("");
        }

        setDocumentsType(updatedListWithNewIds);
    }

    const onchangeHandleDocType = (e, docType) => {
        let value = e.target.value;
        const selectedText = e.target.options[e.target.selectedIndex].text;

        let updatedList = documentsType.map(item => {
            if (item.id == docType.id) {
                if (selectedText.toLowerCase() == "others") {
                    return {
                        ...item,
                        documentTypeId: value,
                        documentTypeName: selectedText,
                        isManualName: true
                    };
                }

                return {
                    ...item,
                    documentTypeId: value,
                    documentTypeName: selectedText,
                    isManualName: false,
                    manualDocumentName: ""
                };
            }
            return item;
        })
        const hasErrors = updatedList.some(item => item.isManualName && !item.manualDocumentName);
        if (!hasErrors) {
            setRequiredManualNameMessage("");
        }
        setDocumentsType(updatedList);
    }

    const setManualName = (e, docType) => {
        let value = e.target.value;

        let updatedList = documentsType.map(item => {
            if (item.id == docType.id) {
                return {
                    ...item,
                    manualDocumentName: value,
                    hasError: false
                };
            }
            return item;
        })
        const hasErrors = updatedList.some(item => item.isManualName && !item.manualDocumentName);
        if (!hasErrors) {
            setRequiredManualNameMessage("");
        }
        setDocumentsType(updatedList);
    }

    const makeDocTypeMandatory = (e, item) => {
        let checked = e.target.checked;

        let updatedList = documentsType.map(docType => {
            if (item.id == docType.id) {
                return {
                    ...docType,
                    isMandatory: checked
                };
            }
            return docType;
        })
        console.log(updatedList);
        setDocumentsType(updatedList);
    }

    useEffect(() => {
        if (documentsType != undefined && documentsType.length == 0) {
            let docTypeList = [...documentsType];
            let initialObj = {
                id: 1,
                documentTypeId: 1,
                documentTypeName: "ID card",
                label: "Documents 01",
                isManualName: false,
                manualDocumentName: "",
                isDeletable: false,
                hasError: false
            }
            docTypeList.push(initialObj);
            setDocumentsType(docTypeList);
        }
    }, [documentsType])

    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <ul className="form-steps">
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar"></li>
                    <li className="step-bar active"></li>
                    <li className="step-bar"></li>
                </ul>
                <img src={closeIcon} onClick={closeRequestDocumentsModal} className="cursor-pointer" />
            </div>
            <div>
                <p className="form-title mb-2"> Setup the booking form</p>
            </div>
            <div className="mb-4">
                <p className="subtitle mb-1">Request documents</p>
                <p className="modal-p-text-black mb-4">
                    Lorem ipsum dolor sit amet consectetur. Ullamcorper netus sed est lorem. Facilisis dictum bibendum tellus eu eget porttitor. Arcu eget molestie sed consectetur integer. Risus malesuada id lacus egestas. Sit maecenas iaculis nibh eget habitasse ac.
                </p>
            </div>
            <div>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            The type of the document <span className="required-icon">*</span>
                        </Accordion.Header>
                        <Accordion.Body className="question-accordion-body pt-1">
                            <div className="d-flex">
                                <div className="col-3"></div>
                                <span className={`text-red fs-small login-field-error ps-0 ${requiredManualNameMessage == "" ? "" : "mb-1"}`}>{requiredManualNameMessage}</span>
                            </div>
                            {
                                documentsType && documentsType.length > 0 ? documentsType.map((item, i) => {
                                    let length = documentsType.length;
                                    return (
                                        <div className={`form-group d-flex align-items-center justify-content-between ${length == 1 ? "mb-0" : i == length - 1 ? "" : "mb-3"}`} key={item.id}>
                                            <label htmlFor="document" className="form-group-label col-2">{item.label}<span className="required-icon">*</span></label>
                                            <select className="form-select" value={item.documentTypeId} onChange={(e) => onchangeHandleDocType(e, item)}>
                                                <option value="1">ID card</option>
                                                <option value="2">Pay slip</option>
                                                <option value="3">Bank statements</option>
                                                <option value="4">Others</option>
                                            </select>
                                            <input type="text" className={`form-control ${item.hasError && item.manualDocumentName == "" ? "border-red" : ""} ${item.isManualName ? "ms-2" : "d-none"}`}
                                                value={item.manualDocumentName} onChange={(e) => setManualName(e, item)} />
                                            <div className="d-flex align-items-center justify-content-start ms-2 mandatory-field">
                                                <label className="modal-p-text-black d-flex align-items-center line-height-0" htmlFor={`documentType${item.id}`}>
                                                    <input className="form-check-input checkbox-red me-2 mt-0" type="checkbox" checked={item.isMandatory} name={`documentType${item.id}`} id={`documentType${item.id}`}
                                                        onChange={ (e) => makeDocTypeMandatory(e, item)} />
                                                    Make it mandatory<span className="required-icon">*</span>
                                                </label>
                                            </div>
                                            <div className="col-1 d-flex align-items-center justify-content-center">
                                                {
                                                    item.isDeletable ?
                                                        <p className="delete-red-cricle bg-danger rounded-5 mb-0 text-white cursor-pointer">
                                                            <img src={deleteIcon} alt="delete" onClick={(e) => clickHandleRemoveDocumentTypeRow(e, item)} />
                                                        </p>
                                                        :
                                                        <img src={plusBlue} className="cursor-pointer align-baseline" alt="add more" onClick={(e) => clickHandleAddMoreDocumentType(e, item)} />
                                                }
                                            </div>
                                        </div>
                                    )

                                })
                                    :
                                    <></>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="d-flex justify-content-center">
                <button type="button" className="filter-btn site-btn-padd border-dark color-dark w-25 me-3" onClick={moveBackToSelectionSummary}>Back</button>
                <button type="button" className="site-btn site-btn-padd w-25" onClick={moveToNextScreen}>Next</button>
            </div>
        </>
    )
}
