import * as React from "react";
import * as ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { variables } from '../Variables';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Schedular } from './Schedular';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";
import { setstatusfilter, clearstatusfilter } from "../features/StatusFilterSlice";
import { setagentfilter, clearagentfilter } from "../features/AgentFilterSlice";
import { setselectedproperty, clearselectedproperty } from "../features/SelectedPropertySlice";
import { setsetting, clearsetting } from "../features/SettingSlice";
import { setSidebarCollapsed } from "../features/SidebarCollapseSlice";
import { setlanguages, resetlanguages } from "../features/LanguageSlice";
import { EventModal } from "./EventModal";
import { ConfirmDeleteWindow } from "./ConfirmDeleteWindow";

import filterIcon from '../assets/images/filter-icon.svg';
import plusIcon from '../assets/images/plus.svg';
import deleteIcon from '../assets/images/trash-white.svg';
import collapseIcon from '../assets/images/collapse.svg';
import expandIcon from '../assets/images/expand.svg';
import logOutIcon from '../assets/images/log-out.svg';
import placeholderLarge from '../assets/images/prop-placeholder-lg.jpg';
import placeholderSmall from '../assets/images/prop-placeholder-sm.jpg';
import plusBlueIcon from '../assets/images/plus_blue.svg';
import locationPin from '../assets/images/location-pin.svg';
import bedIcon from '../assets/images/bed-duo.svg';
import showerIcon from '../assets/images/shower-duo.svg';
import liftIcon from '../assets/images/lift-duo.svg';
import clearCross from '../assets/images/clear-filter-cross.svg';
import filterCross from '../assets/images/filter-cross.svg';
import calLoader from '../assets/images/calendar-loader.svg';

const { useState, useRef } = React;

export const Calendar = ({ showMessage }) => {
    const user = useSelector(state => state.user.user);
    const [selected, setSelected] = useState([]);
    const [isPropertiesCollapsed, setIsPropertiesCollapsed] = useState(false);
    const [clientEstates, setClientEstates] = useState([]);
    const [filteredEstates, setFilteredEstates] = useState([]);
    const [clientToken, setClientToken] = useState('');
    const [height, setHeight] = useState(null);
    const [showHideCalendarFilter, setShowHideCalendarFilter] = useState(false);
    const [whiseAgents, setWhiseAgents] = useState([]);
    const [whiseCalendarEvents, setWhiseCalendarEvents] = useState([]);
    const [whiseCalendarEventId, setWhiseCalendarEventId] = useState(0);
    const [whiseContactId, setWhiseContactId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    //const [officeId, setOfficeId] = useState(user.user.officeId);
    const [confirmedIds, setConfirmedIds] = useState([]);
    const [tobeConfirmedIds, setTobeConfirmedIds] = useState([]);
    const [freeSlotIds, setFreeSlotIds] = useState([]);
    const [calendarActions, setCalendarActions] = useState([]);
    const [officeSettings, setOfficeSettings] = useState({});
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [previewEvent, setPreviewEvent] = useState(false);
    const [showCalendarLoader, setShowCalendarLoader] = useState(true);
    const [estateId, setEstateId] = useState("");
    const [estateSearch, setEstateSearch] = useState("");
    const [purposeStatusId, setPurposeStatusId] = useState("");
    const [displayStatusId, setDisplayStatusId] = useState("");
    const [estateListContainEvent, setEstateListContainEvent] = useState([]);
    const [estateIdsHavingFreeSlotsInFutureOrPast, setEstateIdsHavingFreeSlotsInFutureOrPast] = useState([]);
    const [previewEventDetail, setPreviewEventDetail] = useState(null);
    const [eventToBeAccepted, setEventToBeAccepted] = useState(null);
    const [estateDetailForEventToBeAccepted, setEstateDetailForEventToBeAccepted] = useState(null);
    const [whiseOffice, setWhiseOffice] = useState(null);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [showConfirmWindow, setShowConfirmWindow] = useState(false);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [freeSlotsEstateIdToBeDeleted, setFreeSlotsEstateIdToBeDeleted] = useState(null);
    const [clickedDeleteFreeSlotButton, setClickedDeleteFreeSlotButton] = useState(null);
    const [estateIdsHavingConfiguration, setEstateIdsHavingConfiguration] = useState([]);
    const [confirmWindowMessage, setConfirmWindowMessage] = useState("Do you want to delete past free slots?");
    const estateDropdownManuRef = useRef(null);

    const [isEstateConfigurationsEdit, setIsEstateConfigurationsEdit] = useState(false);

    const clientId = user.user.clientId;
    const officeId = user.user.officeId;
    const contactId = user.user.contactId;
    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const reduxProperty = useSelector(state => state.selectedProperty.selectedProperty);
    const reduxStatus = useSelector(state => state.statusFilter.statusFilter);
    const reduxAgent = useSelector(state => state.agentFilter.agentFilter);
    const reduxEventDetail = useSelector(state => state.eventDetail.eventDetail);
    const actions = useSelector(state => state.calendarActions.calendarActions);
    const reduxClientToken = useSelector(state => state.whiseClientToken.whiseClientToken);
    const reduxIsSidebarCollapsed = useSelector(state => state.isSideBarCollapsed.sidebarCollapsed);

    /*let statusesOptions =;*/

    let container = useRef(null);
    const calendarRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = () => {
        setShowModal(false);
        setTimeout(() => {
            setPreviewEvent(false);
            setIsEstateConfigurationsEdit(false);
        }, 200)
    }

    const handlerSaveFilter = (e) => {

        dispatch(setselectedproperty(selectedProperty));
        dispatch(setagentfilter(selectedAgents));
        dispatch(setstatusfilter(selectedStatuses));
        setShowHideCalendarFilter(false);

        //let filterObj = {
        //    "OfficeId": +officeId,
        //    "clientId": +clientId,
        //    "userId": user.user.userId,
        //    "propertyId": reduxProperty.id,
        //    "agentIds": null,
        //    "freeSlotStatusIds": null,
        //    "toBeConfirmedStatusIds": null,
        //    "confirmedStatusIds": null,
        //}

        //if (reduxAgent != null && reduxAgent.length > 0) {
        //    let agentids = "";
        //    let lengthOfAgents = reduxAgent.length;

        //    reduxAgent.map((item, i) => {
        //        if (i == 0) {
        //            agentids += item.value
        //        }
        //        else {
        //            agentids += ", " + item.value
        //        }
        //    })
        //    filterObj["agentIds"] = agentids;
        //}
        //if (reduxStatus !== undefined && reduxStatus !== null && reduxStatus.length > 0) {
        //    reduxStatus.map(item => {
        //        let calendarActionIds = "";
        //        if (item.value === "1") {
        //            calendarActionIds += item._freeSlotIds[0] + "," + item._freeSlotIds[1];
        //            //calendarActionIds.push(item._freeSlotIds[1]);
        //            filterObj["freeSlotStatusIds"] = calendarActionIds
        //        }
        //        else if (item.value === "2") {
        //            calendarActionIds += item.beforeConfirmationIds[0] + "," + item.beforeConfirmationIds[1];
        //            //calendarActionIds.push(item.beforeConfirmationIds[1]);
        //            filterObj["toBeConfirmedStatusIds"] = calendarActionIds
        //        }
        //        else {
        //            calendarActionIds += item.afterConfirmationIds[0] + "," + item.afterConfirmationIds[1];
        //            //calendarActionIds.push(item.afterConfirmationIds[1]);
        //            filterObj["confirmedStatusIds"] = calendarActionIds
        //        }
        //    })
        //}

        //let url = variables.API_URL + `Filter/SaveFilter?`;

        //const jsonconfig = {
        //    headers: {
        //        'Authorization': `Bearer ${user.tokenValue}`,
        //        'Content-Type': 'application/json'
        //    }
        //};
        //axios.post(url, JSON.stringify(filterObj), jsonconfig) // ASP.NET Core API endpoint with header
        //    .then(response => {
        //        if (response.status == 200) {
        //            showMessage("success", response.data);
        //        }
        //    })
        //    .catch(error => {
        //        console.error('Error fetching data:', error);
        //        e.target.removeAttribute("disabled");
        //        document.querySelector("body").style.cursor = "";
        //    });
    }

    const handleCancelFilter = () => {
        setShowHideCalendarFilter(false);
    }

    const getSavedFilterData = () => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Filter/GetFilter?officeId=` + officeId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var filter = response.data;
                if (filter) {
                    //set saved agents filter to agents redux store
                    if (filter.agentIds != null) {
                        let agentIdsInString = filter.agentIds.split(",")
                        let agentIds = agentIdsInString.map(item => { return +item });
                        let filteredAgents = [];
                        whiseAgents.forEach(el => {
                            if (agentIds.indexOf(el.id) !== -1) {
                                filteredAgents.push({ label: el.name, value: el.id, agentDetail: el });
                            }
                        });
                        dispatch(setagentfilter(filteredAgents));
                    }

                    //set saved status filter to status redux store
                    let selectedStatuses = [];
                    if (filter.confirmedStatusIds != null) {
                        selectedStatuses.push({ label: "Confirmed", value: "3", afterConfirmationIds: confirmedIds });
                    }

                    if (filter.toBeConfirmedStatusIds != null) {
                        selectedStatuses.push({ label: "To be Confirmed", value: "2", beforeConfirmationIds: tobeConfirmedIds });
                    }

                    if (filter.freeSlotStatusIds != null) {
                        selectedStatuses.push({ label: "Free Slot", value: "1", _freeSlotIds: freeSlotIds });
                    }
                    dispatch(setstatusfilter(selectedStatuses));

                    let selectedEstate = clientEstates.filter(item => {
                        return item.id == filter.propertyId
                    })
                    setSelectedProperty(current => selectedEstate[0]);
                    dispatch(setselectedproperty(selectedEstate[0]));
                }
                else {
                    setSelectedProperty(current => clientEstates[0]);
                    dispatch(setselectedproperty(clientEstates[0]));
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const changeHandlerAgentFilter = (e) => {
        let option = e.target.selectedOptions;
        if (option[0].value != "") {
            let item = JSON.parse(option[0].dataset.agent);
            let agent = [{ label: item.name, value: item.id, agentDetail: item }];
            setSelectedAgents(agent);
        }
        else {
            setSelectedAgents([]);
        }
        //dispatch(setagentfilter(e));
    }

    const callBackToUpdateCalendarFilter = (agent) => {
        if (agent.length > 0) {
            setSelectedAgents(agent);
        }
        else {
            setSelectedAgents([]);
        }
    }

    const handlerClearCalenderFilter = (e) => {
        e.stopPropagation();
        setSelectedStatuses([]);
        setSelectedAgents([]);
        dispatch(clearstatusfilter());
        dispatch(clearagentfilter());
    }

    const handleChangeStatus = (item) => {
        setSelectedStatuses(item)
        //dispatch(setstatusfilter(item));
    }

    const getClientWhiseEstatesList = async (clientToken) => {
        var estateLimit = 300;
        var pageOffset = 0;
        let listOfEstates = [];

        let _response = await getWhiseEstatesListByPagin(clientToken, estateLimit, pageOffset);
        if (_response.totalCount != undefined && _response.totalCount > 300) {
            listOfEstates.push(..._response.estates);
            let _afterDivison = _response.totalCount / 300;
            let pages = Math.ceil(_afterDivison);
            for (let i = 1; i < pages; i++) {
                pageOffset = estateLimit * i + 1;
                let _getEstateListResponse = await getWhiseEstatesListByPagin(clientToken, estateLimit, pageOffset);
                if (_getEstateListResponse.estates.length > 0) {
                    listOfEstates.push(..._getEstateListResponse.estates);
                }
            }

            if (listOfEstates.length > 0) {
                setClientEstates(listOfEstates);
                setFilteredEstates(listOfEstates);
            }
        }
        else if (_response.estates != undefined && _response.estates.length > 0) {
            listOfEstates.push(..._response.estates);
            setClientEstates(listOfEstates);
            setFilteredEstates(listOfEstates);
        }
    }

    const getWhiseEstatesListByPagin = async (clientToken, estateLimit, pageOffset) => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };
        let response = await axios.post(url, {
            "Filter": {
                "StatusIds": [1],
                "PurposeStatusIds": [1, 2, 5, 6, 10, 11, 12, 13, 15, 16, 20, 21, 27, 28],
                "DisplayStatusIds": [1, 2, 3, 4, 5],
            },
            "Page": {
                "Limit": estateLimit,
                "Offset": pageOffset
            },
            "Sort": [
                { "Field": "updateDateTime", "Ascending": false }
            ]
        }, config)

        return response.data;
    }

    const getEstateIdsHavingConfigSaved = async () => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Calendar/GetEstateIdsSavedInConfigurations?clientId=${user.user.clientId}`;

        await axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let _esateIdsHavingConfigs = response.data;
                if (_esateIdsHavingConfigs != null) {
                    setEstateIdsHavingConfiguration(_esateIdsHavingConfigs);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }

    const handlerCollapseProperties = () => {
        if (isPropertiesCollapsed == true) {
            setIsPropertiesCollapsed(false);
        }
        else {
            setIsPropertiesCollapsed(true);
        }
        setOpenDropdownId(null);
    }

    const handlerPropertyClick = (property) => {
        //let element = e.target;
        //element.classList.add("selected");
        let currentProp = property;

        setSelectedProperty(current => currentProp);
        dispatch(setselectedproperty(currentProp));
        setOpenDropdownId(null);
    }

    const showCalendarFilters = (e) => {
        if (showHideCalendarFilter == true) {
            setShowHideCalendarFilter(false);
        }
        else {
            setShowHideCalendarFilter(true);
        }
    }

    const getAgents = async (clientToken) => {
        let url = "https://api.whise.eu/v1/admin/representatives/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                setWhiseAgents(response.data.representatives);
            })
            .catch(error => {
                showMessage("error", error);
            });
    }

    const handlerAddSlot = () => {
        setShowModal(true);
    }

    /////////////////////4/8/2024//////////////
    const getOfficeSettings = () => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetSetting?officeId=` + officeId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let settings = response.data;
                if (response.data != null) {
                    setOfficeSettings(response.data);
                    dispatch(setsetting(response.data));

                    let toBeConfirmed = [settings.visitToSaleBefore, settings.visitToRentBefore];
                    let cofirmed = [settings.visitToSaleAfter, settings.visitToRentAfter];
                    let freeslot = [settings.visitToSaleFreeSlot, settings.visitToRentFreeSlot];

                    setTobeConfirmedIds(toBeConfirmed);
                    setConfirmedIds(cofirmed);
                    setFreeSlotIds(freeslot);
                }
                else {
                    //window.location.href = "/settings";
                    navigate("/settings");
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    // Function to handle accept action

    const acceptAction = () => {
        // Your accept action code here
    }

    // Function to handle refuse action
    const refuseAction = () => {
        // Your refuse action code here
    }

    const getAction = (actionId) => {
        let action = calendarActions.filter((d) => d.id === +actionId);
        return action[0].name;
    }

    const sendEmail = (body) => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        let subject = "api_testing_1";
        let html = "<p>A " + getAction(reduxEventDetail.event.EventType) + " request has been booked through smartcalendar online visit planner for:</p>";

        html += "<h5>Contacts</h5>";
        html += "<p>" + reduxEventDetail.invitee.Name + "</p>";
        html += "<p>" + reduxEventDetail.invitee.Email + "</p>";
        html += "<h5>Property</h5>";
        html += "<p>" + reduxProperty.name + "</p>";
        html += "<h5>Date</h5>";
        html += "<p>" + body.StartDateTime + "</p>";
        html += "<button onclick='acceptAction()'>Accept</button>";
        html += "<button onclick='refuseAction()'>Refuse</button>";
        let sendTo = "umarfarooq3540@gmail.com";
        const url = variables.API_URL + `Email/SendEmail?sendEmailTo=${sendTo}&body=${html}&subject=${subject}`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseCalenderEvents = async () => {
        var eventLimit = 300;
        var pageOffset = 0;
        let listOfEvents = [];

        let date = new Date();

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        firstDay.setMinutes(firstDay.getMinutes() - firstDay.getTimezoneOffset());
        firstDay.setMonth(firstDay.getMonth() - 1);

        let isoString = firstDay.toISOString(); // Convert to ISO string
        let startDate = isoString.slice(0, 19); // Remove milliseconds and the 'Z'

        //let startDate = firstDay.toISOString();

        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setHours(23, 59, 59, 0);
        lastDay.setMinutes(lastDay.getMinutes() - lastDay.getTimezoneOffset());
        //lastDay.setDate(lastDay.getDate() + 1);
        //lastDay.setSeconds(lastDay.getSeconds() - 1);
        lastDay.setMonth(lastDay.getMonth() + 1);

        let isoStringEnd = lastDay.toISOString(); // Convert to ISO string
        let endDate = isoStringEnd.slice(0, 19); // Remove milliseconds and the 'Z'

        //let endDate = lastDay.toISOString();

        let _response = await getWhiseCalendarEventsByPagination(clientToken, eventLimit, pageOffset, startDate, endDate)
        if (_response.totalCount != undefined && _response.totalCount > 300) {
            listOfEvents.push(..._response.calendars);
            let _afterDivison = _response.totalCount / 300;
            let pages = Math.ceil(_afterDivison);
            for (let i = 1; i < pages; i++) {
                pageOffset = eventLimit * i + 1;
                let _eventResponse = await getWhiseCalendarEventsByPagination(clientToken, eventLimit, pageOffset, startDate, endDate);
                if (_eventResponse.calendars != undefined && _eventResponse.calendars.length > 0) {
                    listOfEvents.push(..._eventResponse.calendars);
                }
            }

            if (listOfEvents.length > 0) {
                setWhiseCalendarEvents(listOfEvents);
                setShowCalendarLoader(false);
                let eventEstates = [];
                let _estateIdsHavingFreeSlotsInFutureOrPast = [];
                listOfEvents.forEach(item => {
                    let eventDate = new Date(item.startDateTime);
                    let currentDate = new Date();
                    if (item.estates != undefined && item.estates.length > 0 && eventDate > currentDate && freeSlotIds.indexOf(item.action.id) != -1) {
                        eventEstates.push(item.estates[0].estateId);
                    }
                    if (item.estates != undefined && item.estates.length > 0 && freeSlotIds.indexOf(item.action.id) != -1) {
                        _estateIdsHavingFreeSlotsInFutureOrPast.push(item.estates[0].estateId);
                    }
                })
                setEstateListContainEvent(eventEstates);
                setEstateIdsHavingFreeSlotsInFutureOrPast(_estateIdsHavingFreeSlotsInFutureOrPast);
            } else {
                setWhiseCalendarEvents([]);
                setShowCalendarLoader(false);
            }
        }
        else if (_response.calendars != undefined && _response.calendars.length > 0) {
            listOfEvents.push(..._response.calendars);
            setWhiseCalendarEvents(listOfEvents);
            setShowCalendarLoader(false);
            let eventEstates = [];
            let _estateIdsHavingFreeSlotsInFutureOrPast = [];
            listOfEvents.forEach(item => {
                let eventDate = new Date(item.startDateTime);
                let currentDate = new Date();
                if (item.estates != undefined && item.estates.length > 0 && eventDate > currentDate && freeSlotIds.indexOf(item.action.id) != -1) {
                    eventEstates.push(item.estates[0].estateId);
                }
                if (item.estates != undefined && item.estates.length > 0 && freeSlotIds.indexOf(item.action.id) != -1) {
                    _estateIdsHavingFreeSlotsInFutureOrPast.push(item.estates[0].estateId);
                }
            })
            setEstateListContainEvent(eventEstates);
            setEstateIdsHavingFreeSlotsInFutureOrPast(_estateIdsHavingFreeSlotsInFutureOrPast);
        } else {
            setWhiseCalendarEvents([]);
            setShowCalendarLoader(false);
        }
    }

    const getWhiseCalendarEventsByPagination = async (clientToken, eventLimit, pageOffset, startDate, endDate) => {

        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        let body = {
            "Filter": {
                "UpdateDateTimeRange": {
                    "Min": startDate,
                    "Max": endDate
                },
                "IncludeRecurrences": true
            },
            "Page": {
                "Limit": eventLimit,
                "Offset": pageOffset
            }
        };
        let response = await axios.post(url, body, config);

        return response.data;
    }

    const addWhiseCalenderEvent = async (body) => {
        let url = "https://api.whise.eu/v1/calendars/upsert";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, body, config)
            .then(response => {
                setWhiseCalendarEventId(response.data.calendarId);
                if (response.data.calendarId != undefined) {
                    if (reduxEventDetail.invitee != null) {
                        let configuration = {
                            Name: reduxEventDetail.invitee.Name,
                            FirstName: reduxEventDetail.invitee.FirstName,
                            Email: reduxEventDetail.invitee.Email,
                            Mobile: reduxEventDetail.invitee.Mobile,
                            Questions: reduxEventDetail.invitee.Question,
                            Documents: reduxEventDetail.invitee.Documents,
                            AgentRecap: reduxEventDetail.invitee.AgentOnSiteRecap,
                            EventId: response.data.calendarId,
                            ContactId: +contactId,
                        };
                        saveEventConfigurations(configuration);
                    }
                }
                //sendEmail(body);
            })
            .catch(error => {
                showMessage("error", error);
            });
    }

    const deleteWhiseCalendarEvent = async (eventId, eventConfigration) => {
        if (eventConfigration == undefined) {
            setShowCalendarLoader(true);
        }
        let url = "https://api.whise.eu/v1/calendars/delete";
        let headers = {
            'Authorization': `Bearer ${clientToken}`,
            'Content-Type': 'application/json'
        };
        let body = {
            "CalendarIds": [+eventId]
        };
        axios.delete(url, { data: body, headers: headers })
            .then(response => {
                if (response.data) {
                    if (eventConfigration != undefined) {
                        addConfirmedWhiseCalenderEvent(eventConfigration);
                    }
                    else {
                        getWhiseCalenderEvents();
                    }
                }
            })
            .catch(error => {
                console.log("error", error);
            });
    }

    const handleConfirmWindowClose = () => {
        setShowConfirmWindow(false);
        setIsDeleteConfirmed(false);
        setFreeSlotsEstateIdToBeDeleted(null);
        setClickedDeleteFreeSlotButton(null);
    }

    const callBackConfirmDeleteFreeSlots = (flag) => {
        if (flag == true) {
            setIsDeleteConfirmed(flag);
            setShowConfirmWindow(false);
        }
        else {
            setIsDeleteConfirmed(false);
            setShowConfirmWindow(false);
            setFreeSlotsEstateIdToBeDeleted(null);
            setClickedDeleteFreeSlotButton(null);
        }
    }

    useEffect(() => {
        if (isDeleteConfirmed == true && clickedDeleteFreeSlotButton && freeSlotsEstateIdToBeDeleted) {
            let e = clickedDeleteFreeSlotButton;
            let estateId = freeSlotsEstateIdToBeDeleted;

            setShowCalendarLoader(true);
            e.target.setAttribute("disabled", true);
            e.target.style.cursor = "progress";
            document.querySelector("body").style.cursor = "progress";

            let url = variables.API_URL + `calendar/DeleteEstateFreeSlots?clientId=${clientId}&estateId=${estateId}`;

            const config = {
                headers: {
                    'Authorization': `Bearer ${user.tokenValue}`,
                    'Content-Type': 'application/json'
                },
            };

            axios.post(url, {}, config) // ASP.NET Core API endpoint with header
                .then(response => {
                    if (response.status == 200) {
                        getWhiseCalenderEvents();
                        e.target.removeAttribute("disabled");
                        e.target.style.cursor = "";
                        document.querySelector("body").style.cursor = "";
                        setFreeSlotsEstateIdToBeDeleted(null);
                        setClickedDeleteFreeSlotButton(null);
                        setIsDeleteConfirmed(false);
                    }
                })
                .catch(error => {
                    console.error('Error deleting free slots:', error);
                    setShowCalendarLoader(false);
                    e.target.removeAttribute("disabled");
                    e.target.style.cursor = "";
                    document.querySelector("body").style.cursor = "";
                    setFreeSlotsEstateIdToBeDeleted(null);
                    setClickedDeleteFreeSlotButton(null);
                    setIsDeleteConfirmed(false);
                });
        }
    }, [isDeleteConfirmed, clickedDeleteFreeSlotButton, freeSlotsEstateIdToBeDeleted])

    const handleDeleteAllFreeSlotsClick = async (e, estateId) => {
        setShowConfirmWindow(true);
        setFreeSlotsEstateIdToBeDeleted(estateId);
        setClickedDeleteFreeSlotButton(e);
    }

    const acceptToBeConfirmedEvents = async (event) => {
        setEventToBeAccepted(event)
        getEstateById(event.estates[0].estateId)
    }

    const getEstateById = (estateId) => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post('/user', document.querySelector('#my-form'), {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        axios.post(url, {
            "Filter": {
                "EstateIds": [estateId],
            }
        }, config)
            .then(response => {
                if (response.data.estates != undefined) {
                    setEstateDetailForEventToBeAccepted(response.data.estates);
                }
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const addConfirmedWhiseCalenderEvent = async (body) => {
        let url = "https://api.whise.eu/v1/calendars/upsert";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, body, config)
            .then(response => {
                sendSecondEmail();
                setShowCalendarLoader(false);
            })
            .catch(error => {
                console.log("error", error)
            });
    }

    const sendSecondEmail = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        let subject = "Booking Confirmed";
        let estate = estateDetailForEventToBeAccepted[0];



        let html = "<div style='border: 1px solid #FAFAFA; background-color: white; border-radius: 16px; padding: 20px; width: 80%; margin: 0 auto; text-align: left'>";
        html += "   <div><img src='https://mink-smartcalendar-agent-ui.net7.be/static/media/sidebar-logo.c2f1f3f5618f7e01cbfd4410196de7f5.svg' style='margin-bottom: 1.5rem' /></div>";
        html += "   <h5 style='font-size: 24px;font-weight: 500;color: #111827;font-family: Poppins; margin: 0; margin-bottom: 1.5rem'>Your Visit request has been accepted by <strong>" + eventToBeAccepted.users[0].name + "</strong> of the agency " + eventToBeAccepted.users[0].officeId + "</h5>";
        html += "   <div  style='margin-bottom: 1.5rem; border: 1px solid #FAFAFA; border-radius: 8px; text-align: left; padding: 15px;'>";
        html += "       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>You're visiting the property located:</h5>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem'>" + (estate.address == undefined ? "" : estate.address) + " " + (estate.number == undefined ? "" : estate.number) + ", " + (estate.zip == undefined ? "" : estate.zip) + " " + (estate.city == undefined ? "" : estate.city) + "</p>";
        html += "       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem; margin-top: 1.5rem'>Date</h5>";
        html += "       <p style='font-family: Poppins; font-size: 14px; line-height: 20.8px; color: #111827; margin: 0;'>" + new Date(eventToBeAccepted.startDateTime) + "</p>";
        html += "   </div>";
        html += "   <div style='margin-bottom: 1.5rem; background-color: #FBEFEF; border-radius: 8px; text-align: left; padding: 15px;'>";
        html += "       <h5 style='font-family: Poppins; font-size: 16px; line-height: 20.8px; color: #111827; margin: 0; margin-bottom: 1rem; color: #CF132A'>Remarque</h5>";
        html += "       <p style='margin: 0; font-family: Poppins; font-size: 14px;'>You will receive second email confirmation from the agency with the electronic invitation to add on your agenda. Later, if you're not able to attend to the visit appointment please come back on this email and click on the button below OR call the agency at +32 02 487 878</p>";
        html += "   </div>";
        html += "   <div style='display: flex; align-items: center; justify-content: space-between; font-family: Poppins'>"
        html += "       <a href='#' style='margin-right: 10px; border: 1px solid #111827; text-decoration: none; width: 100%; padding: 9px 10px; border-radius: 8px; text-align: center; color: #111827; font-size: 14px'>Cancel the Visit</a>"
        html += "       <a href='#' style='background-color: #274B97; color: white; text-decoration: none; width: 100%; padding: 10px; border-radius: 8px; text-align: center;font-size: 14px'>Reschedule the Visit</a>"
        html += "   </div>"
        html += "</div> ";


        let email = "";
        if (eventToBeAccepted.contacts[0].privateEmail != undefined && eventToBeAccepted.contacts[0].privateEmail != "") {
            email = eventToBeAccepted.contacts[0].privateEmail;
        }
        else {
            email = eventToBeAccepted.contacts[0].businessEmail;
        }
        let sendTo = email;

        let objEmail = {
            emailTo: sendTo,
            body: html,
            subject: subject
        }

        const url = variables.API_URL + `Email/SendEmail?`;
        axios.post(url, JSON.stringify(objEmail), jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                var resp = response.data;
                setEventToBeAccepted(null);
                setEstateDetailForEventToBeAccepted(null);
                setShowCalendarLoader(true);
                handleClose();
                getWhiseCalenderEvents();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const saveEventConfigurations = (config) => {

        let url = variables.API_URL + `InviteeConfiguration/SaveConfigurations?`;
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${user.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        axios.post(url, JSON.stringify(config), jsonconfig) // ASP.NET Core API endpoint with header
            .then(response => {
                handleClose();
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const addContactOnWhise = async (body) => {
        let url = "https://api.whise.eu/v1/contacts/create";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, body, config)
            .then(response => {
                setWhiseContactId(response.data.contactId);
                if (response.data.contactId > 0) {
                    if (reduxEventDetail.event != null && reduxEventDetail.availableHours != null) {

                        let agentIds = reduxAgent.map(item => { return item.value });
                        let address = "";
                        if (reduxEventDetail.event.EventAddress == "property") {
                            address += reduxProperty.address == undefined ? "" : reduxProperty.address + " ";
                            address += reduxProperty.number == undefined ? "" : reduxProperty.number + ", ";
                            address += reduxProperty.zip == undefined ? "" : reduxProperty.zip;
                            address += reduxProperty.city == undefined ? "" : " " + reduxProperty.city;
                        }
                        else {
                            address += reduxProperty.address == undefined ? "" : reduxProperty.address + " ";
                            address += reduxProperty.number == undefined ? "" : reduxProperty.number + ", ";
                            address += reduxProperty.zip == undefined ? "" : reduxProperty.zip;
                            address += reduxProperty.city == undefined ? "" : " " + reduxProperty.city;
                        }

                        let subject = getAction(reduxEventDetail.event.EventType) + ": " + address;

                        //let subject = getAction(reduxEventDetail.event.EventType);

                        reduxEventDetail.availableHours.forEach(item => {
                            let configration = {
                                "CalendarActionId": reduxEventDetail.event.EventType,
                                "StartDateTime": item.StartDateTime,
                                "EndDateTime": item.EndDateTime,
                                "AllDay": item.AllDay,
                                "Private": false,
                                "Subject": subject,//"testing",
                                "UserIds": agentIds,
                                "EstateIds": [reduxProperty.id],
                                "ContactIds": [response.data.contactId]
                            }
                            addWhiseCalenderEvent(configration);
                        })
                    }
                }
            })
            .catch(error => {
                showMessage("error", error);
            });
    }

    const saveNewWhiseEvent = async (e) => {
        //if (reduxEventDetail.invitee != null) {
        //    let contactbody = {
        //        Name: reduxEventDetail.invitee.Name,
        //        PrivateEmail: reduxEventDetail.invitee.Email,
        //        OfficeIds: [+officeId],
        //        CountryId: 1,
        //        StatusId: 1,
        //        FunnelStatusId: 3,
        //        LanguageId: 'fr-BE'
        //    };
        //    let contactid = await addContactOnWhise(contactbody);
        //}
        e.target.setAttribute("disabled", true);
        e.target.style.cursor = "progress";
        document.querySelector("body").style.cursor = "progress";
        if (contactId > 0) {
            if (reduxEventDetail.event != null && reduxEventDetail.availableHours != null) {

                let agentIds = reduxAgent.map(item => { return item.value });
                let address = "";
                if (reduxEventDetail.event.EventAddress == "property") {
                    address += reduxProperty.address == undefined ? "" : reduxProperty.address + " ";
                    address += reduxProperty.number == undefined ? "" : reduxProperty.number + ", ";
                    address += reduxProperty.zip == undefined ? "" : reduxProperty.zip;
                    address += reduxProperty.city == undefined ? "" : " " + reduxProperty.city;
                }
                else {
                    address += whiseOffice.address == undefined ? "" : whiseOffice.address + " ";
                    address += whiseOffice.number == undefined ? "" : whiseOffice.number + ", ";
                    address += whiseOffice.zip == undefined ? "" : whiseOffice.zip;
                    address += whiseOffice.city == undefined ? "" : " " + whiseOffice.city;
                }

                let subject = getAction(reduxEventDetail.event.EventType) + ": " + address;
                //let subject = getAction(reduxEventDetail.event.EventType);

                const promises = [];
                reduxEventDetail.availableHours.forEach(item => {
                    // add all promises that will be executed into array
                    promises.push(
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                let configration = {
                                    "CalendarActionId": reduxEventDetail.event.EventType,
                                    "StartDateTime": item.StartDateTime,
                                    "EndDateTime": item.EndDateTime,
                                    "AllDay": item.AllDay,
                                    "Private": false,
                                    "Subject": subject, //"testing",
                                    "UserIds": agentIds,
                                    "EstateIds": [reduxProperty.id],
                                    "ContactIds": [+contactId]
                                }
                                addWhiseCalenderEvent(configration);
                                resolve();
                            }, 3000);
                        })
                            .catch((error) => {
                                e.target.removeAttribute("disabled");
                                e.target.style.cursor = "";
                                document.querySelector("body").style.cursor = "";
                            }));
                })

                // execute all promises here
                Promise.all(promises).then(() => {
                    e.target.removeAttribute("disabled");
                    e.target.style.cursor = "";
                    document.querySelector("body").style.cursor = "";
                });
            }
        }
    }

    const clickHandlerEventPreview = async (e) => {
        let eventDetail = e.event.extendedProps;
        setPreviewEventDetail(eventDetail)
        setShowModal(true);
        setPreviewEvent(true);
    }

    const changeHandlerSearchByEstateId = (e) => {
        let value = e.target.value;
        let isnum = /^\d+$/.test(value);

        let filteredList = clientEstates;
        if (estateSearch != "") {
            let listAfterFirstFilter = filteredList.filter(item => {
                let itemPrice = item.price != undefined ? item.price + "" : "";
                let city = item.city != undefined ? item.city.toLowerCase() : "";
                let address = item.address != undefined ? item.address.toLowerCase() : "";
                let zip = item.zip != undefined ? item.zip.toLowerCase() : "";
                let number = item.number != undefined ? item.number.toLowerCase() : "";
                return city.includes(estateSearch) || address.includes(estateSearch) || itemPrice.includes(estateSearch) || zip.includes(estateSearch) || number.includes(estateSearch);
            });

            filteredList = listAfterFirstFilter;
        }
        if (purposeStatusId != "") {
            let listAfterSecondFilter = filteredList.filter(item => {
                return item.purpose.id == purposeStatusId;
            });
            filteredList = listAfterSecondFilter;
        }
        if (displayStatusId != "") {
            let listAfterThirdFilter = filteredList.filter(item => {
                return item.displayStatusId == displayStatusId;
            });
            filteredList = listAfterThirdFilter;
        }

        if (value !== "" && isnum == true) {
            setEstateId(value);
            let filteredEstatesList = filteredList.filter(item => {
                let itemEstateId = item.id + "";
                return itemEstateId.includes(value);
            })
            setFilteredEstates(filteredEstatesList);
        }
        else if (value == "") {
            setEstateId("");
            setFilteredEstates(filteredList);
        }
    }

    const changeHandlerSearchByEstateProps = (e) => {
        let value = e.target.value.toLowerCase();

        let filteredList = clientEstates;
        if (estateId != "") {
            let listAfterFirstFilter = filteredList.filter(item => {
                let itemEstateId = item.id + "";
                return itemEstateId.includes(estateId);
            });
            filteredList = listAfterFirstFilter
        }
        if (purposeStatusId != "") {
            let listAfterSecondFilter = filteredList.filter(item => {
                return item.purpose.id == purposeStatusId;
            });
            filteredList = listAfterSecondFilter;
        }
        if (displayStatusId != "") {
            let listAfterThirdFilter = filteredList.filter(item => {
                return item.displayStatusId == displayStatusId;
            });
            filteredList = listAfterThirdFilter;
        }

        if (value !== "") {
            setEstateSearch(value);
            let filteredEstatesList = filteredList.filter(item => {
                let itemPrice = item.price != undefined ? item.price + "" : "";
                let city = item.city != undefined ? item.city.toLowerCase() : "";
                let address = item.address != undefined ? item.address.toLowerCase() : "";
                let zip = item.zip != undefined ? item.zip.toLowerCase() : "";
                let number = item.number != undefined ? item.number.toLowerCase() : "";
                return city.includes(value) || address.includes(value) || itemPrice.includes(value) || zip.includes(value) || number.includes(value);
            })
            setFilteredEstates(filteredEstatesList);
        }
        else {
            setEstateSearch("");
            setFilteredEstates(filteredList);
        }

    }

    const changeHandlerPurposeStatusFilter = (e) => {
        let value = e.target.value;

        let filteredList = clientEstates;
        if (estateSearch != "") {
            let listAfterFirstFilter = filteredList.filter(item => {
                let itemPrice = item.price != undefined ? item.price + "" : "";
                let city = item.city != undefined ? item.city.toLowerCase() : "";
                let address = item.address != undefined ? item.address.toLowerCase() : "";
                let zip = item.zip != undefined ? item.zip.toLowerCase() : "";
                let number = item.number != undefined ? item.number.toLowerCase() : "";
                return city.includes(estateSearch) || address.includes(estateSearch) || itemPrice.includes(estateSearch) || zip.includes(estateSearch) || number.includes(estateSearch);
            });
            filteredList = listAfterFirstFilter;
        }
        if (estateId != "") {
            let listAfterSecondFilter = filteredList.filter(item => {
                let itemEstateId = item.id + "";
                return itemEstateId.includes(estateId);
            });
            filteredList = listAfterSecondFilter;
        }
        if (displayStatusId != "") {
            let listAfterThirdFilter = filteredList.filter(item => {
                return item.displayStatusId == displayStatusId;
            });
            filteredList = listAfterThirdFilter;
        }

        if (value != "") {
            setPurposeStatusId(value);
            let filteredEstatesList = filteredList.filter(item => {
                return item.purpose.id == value;
            })
            setFilteredEstates(filteredEstatesList);
        }
        else {
            setPurposeStatusId("");
            setFilteredEstates(filteredList);
        }
    }

    const changeHandlerDisplayStatusFilter = (e) => {
        let value = e.target.value;

        let filteredList = clientEstates;
        if (estateSearch != "") {
            let listAfterFirstFilter = filteredList.filter(item => {
                let itemPrice = item.price != undefined ? item.price + "" : "";
                let city = item.city != undefined ? item.city.toLowerCase() : "";
                let address = item.address != undefined ? item.address.toLowerCase() : "";
                let zip = item.zip != undefined ? item.zip.toLowerCase() : "";
                let number = item.number != undefined ? item.number.toLowerCase() : "";
                return city.includes(estateSearch) || address.includes(estateSearch) || itemPrice.includes(estateSearch) || zip.includes(estateSearch) || number.includes(estateSearch);
            });
            filteredList = listAfterFirstFilter;
        }
        if (estateId != "") {
            let listAfterSecondFilter = filteredList.filter(item => {
                let itemEstateId = item.id + "";
                return itemEstateId.includes(estateId);
            });
            filteredList = listAfterSecondFilter;
        }
        if (purposeStatusId != "") {
            let listAfterThirdFilter = filteredList.filter(item => {
                return item.purpose.id == purposeStatusId;
            });
            filteredList = listAfterThirdFilter;
        }

        if (value != "") {
            setDisplayStatusId(value);
            let filteredEstatesList = filteredList.filter(item => {
                return item.displayStatusId == value;
            })
            setFilteredEstates(filteredEstatesList);
        }
        else {
            setDisplayStatusId("");
            setFilteredEstates(filteredList);
        }
    }

    const handlerClearEstateFilter = (e) => {
        setDisplayStatusId("");
        setPurposeStatusId("");

        let filteredList = clientEstates;
        if (estateSearch != "") {
            let listAfterFirstFilter = filteredList.filter(item => {
                let itemPrice = item.price != undefined ? item.price + "" : "";
                let city = item.city != undefined ? item.city.toLowerCase() : "";
                let address = item.address != undefined ? item.address.toLowerCase() : "";
                let zip = item.zip != undefined ? item.zip.toLowerCase() : "";
                let number = item.number != undefined ? item.number.toLowerCase() : "";
                return city.includes(estateSearch) || address.includes(estateSearch) || itemPrice.includes(estateSearch) || zip.includes(estateSearch) || number.includes(estateSearch);
            });
            filteredList = listAfterFirstFilter;
        }
        if (estateId != "") {
            let listAfterSecondFilter = filteredList.filter(item => {
                let itemEstateId = item.id + "";
                return itemEstateId.includes(estateId);
            });
            filteredList = listAfterSecondFilter;
        }
        setFilteredEstates(filteredList);
    }

    const formatPrice = (price, symbol) => {
        let formattedPrice = new Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(price,);
        //let index = formattedPrice.indexOf(symbol);

        //let finalPrice = formattedPrice.substr(index+1)

        //return finalPrice + " " + symbol;
        return formattedPrice;
    }

    const getWhiseOffices = () => {
        let url = "https://api.whise.eu/v1/admin/offices/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${whiseUserToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": clientId }, config)
            .then(response => {
                console.log(response.data.offices);
                if (response.data.offices != undefined) {
                    var _filteredOffice = response.data.offices.filter((item) => { return item.id == officeId });
                    setWhiseOffice(_filteredOffice[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handleDropdownToggle = (e, dropdownId, property) => {
        e.stopPropagation();
        handlerPropertyClick(property);
        setOpenDropdownId(prevId => prevId === dropdownId ? null : dropdownId); // Toggle dropdown visibility
    };

    //languages save in redux store 
    const getAllLanguage = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetAllLanguages`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let getLanguages = response.data;
                dispatch(setlanguages(getLanguages));
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const copToClipBoard = (link) => {
        navigator.clipboard.writeText(link);
    }

    const editEstateConfigurations = (e, estateId) => {
        setIsEstateConfigurationsEdit(true);
        setShowModal(true);
    }

    useEffect(() => {
        if (eventToBeAccepted != null && estateDetailForEventToBeAccepted != null) {
            setShowModal(false);
            setShowCalendarLoader(true);
            let confirmedId = confirmedIds[0];
            if (estateDetailForEventToBeAccepted[0].purpose.id === 2) {
                confirmedId = confirmedIds[1];
            }

            let subject = getAction(confirmedId);

            let address = "";
            address += estateDetailForEventToBeAccepted[0].address == undefined ? "" : estateDetailForEventToBeAccepted[0].address + " ";
            address += estateDetailForEventToBeAccepted[0].number == undefined ? "" : estateDetailForEventToBeAccepted[0].number + ", ";
            address += estateDetailForEventToBeAccepted[0].zip == undefined ? "" : estateDetailForEventToBeAccepted[0].zip;
            address += estateDetailForEventToBeAccepted[0].city == undefined ? "" : " " + estateDetailForEventToBeAccepted[0].city;

            let configration = {
                "CalendarActionId": confirmedId,
                "StartDateTime": eventToBeAccepted.startDateTime,
                "EndDateTime": eventToBeAccepted.endDateTime,
                "AllDay": "false",
                "Private": false,
                "subject": subject, //"private",
                "UserIds": [eventToBeAccepted.users[0].userId],
                "EstateIds": [estateDetailForEventToBeAccepted[0].id],
                "ContactIds": [eventToBeAccepted.contacts[0].contactId]
            }
            deleteWhiseCalendarEvent(eventToBeAccepted.eventId, configration);
        }
    }, [eventToBeAccepted, estateDetailForEventToBeAccepted])

    useEffect(() => {
        setShowCalendarLoader(true);
        if (clientToken != "") {
            let body = {}
            let calendarActionIds = [];
            if (selectedProperty != null && selectedAgents.length == 0) {
                body.EstateIds = [selectedProperty.id]
            }
            getWhiseCalenderEvents();
        }
    }, [clientToken, selectedAgents, selectedProperty, selectedStatuses, whiseCalendarEventId, freeSlotIds]);

    useEffect(() => {
        if (whiseUserToken && reduxClientToken != null) {
            //getClientToken();
            setClientToken(reduxClientToken);
            getAgents(reduxClientToken);
            getClientWhiseEstatesList(reduxClientToken);
            getEstateIdsHavingConfigSaved();
            getWhiseOffices();
        }
    }, [whiseUserToken, reduxClientToken])

    useEffect(() => {
        if (actions != null && actions != undefined) {
            setCalendarActions(actions);
        }
    }, [actions])

    useEffect(() => {
        if (container != null) {
            let contHeight = container.current.offsetHeight;
            setHeight(contHeight);
        }
        //if (document.getElementById("schedularContainer") != null) {
        //    let calendarHeight = document.getElementById("schedularContainer").offsetHeight;
        //    setHeight(calendarHeight - 42);
        //}
    }, [container]);

    useEffect(() => {
        if (reduxProperty != null) {
            setSelectedProperty(reduxProperty);
            //dispatch(setselectedproperty(reduxProperty));
        }
        else if (reduxProperty == null && clientEstates.length > 0) {
            setSelectedProperty(current => clientEstates[0]);
            dispatch(setselectedproperty(clientEstates[0]));
        }

    }, [clientEstates])

    useEffect(() => {
        getOfficeSettings();
        getAllLanguage();
        if (reduxStatus != undefined && reduxStatus !== null && reduxStatus.length > 0) {
            setSelectedStatuses(reduxStatus);
        }
        if (reduxAgent != undefined && reduxAgent != null) {
            setSelectedAgents(reduxAgent);
        }
        setTimeout(() => {
            if (reduxIsSidebarCollapsed == false) {
                dispatch(setSidebarCollapsed(true));
            }
            setIsPropertiesCollapsed(true);
        }, 5000);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openDropdownId !== null && !event.target.closest('.estate-drop-menu')) {
                setOpenDropdownId(null); // Close dropdown when clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdownId]);

    return (
        <>
            <div className={`${isPropertiesCollapsed == true ? "ps-4 pe-0" : "px-4"}`}>
                <div className="row">
                    <div className={`${isPropertiesCollapsed == true ? "col-sm-11" : "col-12 col-sm-8 col-md-9 col-lg-9 col-xl-9"}`}>
                        <div className="d-flex align-items-center justify-content-between pb-3">
                            <span className="form-title">
                                Calendar
                            </span>
                            <div className="d-flex">
                                <Dropdown.Toggle className={`filter-btn ${showHideCalendarFilter == true ? "dropup" : ""}`}
                                    id="calendarFilter" onClick={showCalendarFilters} >
                                    <img src={filterIcon} className="pe-2" />
                                    <span className="pe-3">Filter</span>
                                    {
                                        reduxAgent != null && reduxAgent.length != 0 || reduxStatus != null && reduxStatus !== undefined && reduxStatus.length != 0 ?
                                            <img src={clearCross} className="me-1" onClick={handlerClearCalenderFilter} />
                                            : <></>
                                    }
                                </Dropdown.Toggle>
                                <button className="site-btn ms-2" onClick={handlerAddSlot}>
                                    <img src={plusIcon} />Add Slot
                                </button>
                            </div>
                        </div>
                        <div className={`form-container mb-3 ${showHideCalendarFilter == true ? "" : "d-none"}`}>
                            <div className="row mb-3">
                                <div className="col-md-4 col-12">
                                    <div className="form-group">
                                        <label className="form-group-label mt-0" htmlFor="property">Property</label>
                                        <select className="form-select" id="property" disabled>
                                            {
                                                selectedProperty != null && selectedProperty.id != undefined ?
                                                    <option value={selectedProperty.id}>{selectedProperty.name}</option>
                                                    :
                                                    <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="form-group">
                                        <label className="form-group-label mt-0" htmlFor="agent">Agent<span>*</span></label>
                                        <select className="form-select" onChange={changeHandlerAgentFilter} value={selectedAgents.length > 0 ? selectedAgents[0].value : ""}>
                                            <option value="">Select Agent</option>
                                            {
                                                whiseAgents.length > 0 ? whiseAgents.map((item, i) => {
                                                    return (
                                                        <option value={item.id} data-agent={JSON.stringify(item)} key={i}>{item.firstName + " " + item.name}</option>
                                                    )
                                                })
                                                    :
                                                    <></>
                                            }
                                        </select>
                                        {/*<MultiSelect*/}
                                        {/*    id="agent"*/}
                                        {/*    hasSelectAll={false}*/}
                                        {/*    disableSearch={true}*/}
                                        {/*    closeOnChangedValue={true}*/}
                                        {/*    multiple={false}*/}
                                        {/*    value={selectedAgents.length > 0 ? selectedAgents : []}*/}
                                        {/*    options={() => {*/}
                                        {/*        if (whiseAgents.length > 0) {*/}
                                        {/*            return whiseAgents.map((item, index) => {*/}
                                        {/*                return { label: item.name, value: item.id, agentDetail: item }*/}
                                        {/*            })*/}
                                        {/*        }*/}
                                        {/*        else {*/}
                                        {/*            return []*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*    onChange={changeHandlerAgentFilter}*/}
                                        {/*    labelledBy="Select an option"*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="form-group">
                                        <label className="form-group-label mt-0" htmlFor="status">Status</label>
                                        <MultiSelect
                                            id="transactionType"
                                            value={selectedStatuses.length > 0 ? selectedStatuses : []}
                                            options={() => {
                                                return [
                                                    { label: "Free Slot", value: "1", _freeSlotIds: freeSlotIds },
                                                    { label: "To be Confirmed", value: "2", beforeConfirmationIds: tobeConfirmedIds },
                                                    { label: "Confirmed", value: "3", afterConfirmationIds: confirmedIds }
                                                ]
                                            }}
                                            onChange={handleChangeStatus}
                                            labelledBy="Select an option"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-end">
                                    <button className="site-btn-transparent" onClick={handleCancelFilter}>Cancel</button>
                                    <button className="site-btn ms-3" onClick={handlerSaveFilter}>Save</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Schedular showMessageBox={showMessage} selectedProperty={selectedProperty} calendarRef={calendarRef} calendarEvents={whiseCalendarEvents} tobeConfirmedIds={tobeConfirmedIds} confirmedIds={confirmedIds} freeSlotIds={freeSlotIds} eventClickHandler={clickHandlerEventPreview} selectedAgentsList={selectedAgents} selectedStatusAction={selectedStatuses} showCalendarLoader={showCalendarLoader} />
                        </div>
                    </div>
                    <div className={`${isPropertiesCollapsed == true ? "col-sm-1 pe-0" : "col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3"}`} ref={container}>
                        <div className={`props-list d-flex align-items-center justify-content-between pb-3 ${isPropertiesCollapsed == true && "invisible overflow-hidden"}`}>
                            <span className="form-title">
                                Properties ({filteredEstates.length})
                            </span>
                            <img src={collapseIcon} onClick={handlerCollapseProperties} />
                        </div>
                        <div className={`${isPropertiesCollapsed == true ? "" : "d-flex flex-row-reverse align-items-center justify-content-between mb-2"}`}>
                            <Dropdown>
                                <Dropdown.Toggle className={`filter-btn filter-btn-estates ${isPropertiesCollapsed == true ? "filter-caret mb-2" : ""}`} id="propertyFilter">
                                    <img src={filterIcon} className="pe-2" />
                                    {
                                        isPropertiesCollapsed == true && (purposeStatusId != "" || displayStatusId != "") ? "" :
                                            <span className="pe-2">Filter</span>
                                    }
                                    {
                                        purposeStatusId != "" || displayStatusId != "" ?
                                            isPropertiesCollapsed == true ?
                                                <img src={filterCross} className="me-2" onClick={handlerClearEstateFilter} />
                                                :
                                                <img src={clearCross} className="me-1" onClick={handlerClearEstateFilter} />
                                            :
                                            ""
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="estate-filter-dd">
                                    <div className="form-group">
                                        <label className="form-group-label mt-0">Purpose Status</label>
                                        <select className="form-select filter-select mb-2" value={purposeStatusId} onChange={changeHandlerPurposeStatusFilter}>
                                            <option value="">Select Purpose Status</option>
                                            <option value="1">For sale</option>
                                            <option value="2">For rent</option>
                                            <option value="3">Life annuity sale</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-group-label mt-0">Display Status</label>
                                        <select className="form-select filter-select" value={displayStatusId} onChange={changeHandlerDisplayStatusFilter}>
                                            <option value="">Select Display Status</option>
                                            <option value="1">Offline</option>
                                            <option value="2">Online</option>
                                            <option value="3">EstateOfTheMonth</option>
                                            <option value="4">Realisations</option>
                                            <option value="5">OnlineWebsiteOnly</option>
                                        </select>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className={`position-relative ${isPropertiesCollapsed == true ? "mb-2" : "me-2"}`}>
                                <input type="search" className="form-control search-box" placeholder="Search by estate Id" value={estateId} onChange={changeHandlerSearchByEstateId} />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className={`search-icon`} />
                            </div>
                        </div>
                        <div className="position-relative mb-2">
                            <input type="search" className="form-control search-box me-2" placeholder="Search by street, number, city, zip or price" value={estateSearch} onChange={changeHandlerSearchByEstateProps} />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
                        </div>
                        <div className={`${isPropertiesCollapsed == true ? "form-container form-container-collapsed" : "prop-container"}`} style={{ height: `${isPropertiesCollapsed == true ? "auto" : "83.5vh"}` }}>
                            <img src={expandIcon} className={`${isPropertiesCollapsed == true ? "d-block m-auto mb-3" : "d-none"}`} onClick={handlerCollapseProperties} />
                            <div className={`${isPropertiesCollapsed == true ? "collapsed-properties-scroll" : ""}`} style={{ height: `${isPropertiesCollapsed == true ? "83.5vh" : "auto"}` }}>
                                {
                                    filteredEstates.length > 0 ? filteredEstates.map((item, index) => {

                                        const isFound = selectedProperty != null && selectedProperty.id != undefined && item.id == selectedProperty.id ? true : false;

                                        var selectedClass = isFound == true ? "selected" : "";

                                        let isFreeSlotExistForEstate = false;
                                        let isEstateFutureOrPastFreeSlot = false;
                                        let isConfigsSaved = false;

                                        if (estateIdsHavingConfiguration.length > 0 && estateIdsHavingConfiguration.includes(item.id)) {
                                            isConfigsSaved = true;
                                        }

                                        if (estateListContainEvent.length > 0) {
                                            isFreeSlotExistForEstate = estateListContainEvent.indexOf(item.id) >= 0 ? true : false;
                                        }

                                        if (estateIdsHavingFreeSlotsInFutureOrPast.length > 0) {
                                            isEstateFutureOrPastFreeSlot = estateIdsHavingFreeSlotsInFutureOrPast.indexOf(item.id) >= 0 ? true : false;
                                        }

                                        if (isPropertiesCollapsed == true) {
                                            let propertyImage = placeholderSmall;
                                            if (item.pictures != undefined && item.pictures.length > 0) {
                                                propertyImage = item.pictures[0].urlSmall;
                                            }
                                            return (
                                                <OverlayTrigger key={index} delay={{ hide: 0, show: 300 }} trigger="click" rootClose placement="left" x-placement="left"
                                                    overlay={(props) => (
                                                        <Tooltip className="prop-tooltip" {...props}>
                                                            <div className={`form-container position-relative form-container-expanded ${selectedClass}`}>
                                                                <div className="prop-img-container position-relative">
                                                                    {
                                                                        item.price != undefined ?
                                                                            <span className="price-tag">{formatPrice(item.price, item.currency)}</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                    <a href={`${variables.Booking_URL}${item.id}/${clientId}/${officeId}/${contactId}`} className={`btn-booking ${isFreeSlotExistForEstate == true ? "" : "d-none"}`} target="_blank">Booking</a>
                                                                    <img src={propertyImage} className="property-img" />
                                                                </div>
                                                                <div className="property-name mt-3">
                                                                    <span>{item.name} ({item.id})</span>
                                                                    <span className="add-slot-link" onClick={handlerAddSlot}>
                                                                        <img src={plusBlueIcon} className="me-2" /> Add Slot
                                                                    </span>
                                                                </div>

                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="prop-address my-2">
                                                                        {/*<img src={locationPin} className="me-2" />*/}
                                                                        <p className="mb-0">
                                                                            {
                                                                                (item.address == undefined ? "" : item.address) + " " +
                                                                                (item.number == undefined ? "" : item.number) + ""
                                                                            }
                                                                        </p>
                                                                        <p className="mb-0">
                                                                            {
                                                                                (item.zip == undefined ? "" : item.zip) + " " +
                                                                                (item.city == undefined ? "" : item.city)
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <Dropdown className="estate-drop-menu" show={openDropdownId === item.id} onClick={(e) => handleDropdownToggle(e, item.id, item)}>
                                                                        <Dropdown.Toggle className="estate-toggle-menu" variant="light" id={`dropdown-basic${item.id}`}>
                                                                            <FontAwesomeIcon icon={faEllipsisVertical} className="color-blue" />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="text-center p-0">
                                                                            <button className={`drop-menu-btn text-start ${isFreeSlotExistForEstate == true ? "" : "d-none"}`} onClick={() => copToClipBoard(`${variables.Booking_URL}${item.id}/${clientId}/${officeId}/${contactId}`)}>Copy booking link</button>
                                                                            <hr className={`mx-2 my-0 ${isFreeSlotExistForEstate == true ? "" : "d-none"}`} />
                                                                            <button className={`drop-menu-btn text-start ${isEstateFutureOrPastFreeSlot ? "" : "d-none"}`} onClick={(e) => handleDeleteAllFreeSlotsClick(e, item.id)}>Delete all free slots</button>
                                                                            <hr className={`mx-2 my-0 ${isEstateFutureOrPastFreeSlot && isConfigsSaved? "" : "d-none"}`} />
                                                                            <button className={`drop-menu-btn text-start ${isConfigsSaved == true ? "": "d-none"}`}
                                                                                onClick={(e) => editEstateConfigurations(e, item.id)}>
                                                                                Edit Form
                                                                            </button>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <div className="row prop-detail-row mt-3">
                                                                    <div className="col-4 d-flex align-items-center justify-content-center border-end">
                                                                        <img src={bedIcon} className={`${item.bedRooms != undefined ? "me-1" : ""}`} />
                                                                        <span className="smallImgIcon-Modal-info">
                                                                            {
                                                                                item.bedRooms != undefined ?
                                                                                    item.bedRooms
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-4 d-flex align-items-center justify-content-center border-end">
                                                                        <img src={showerIcon} className={`${item.bathRooms != undefined ? "me-1" : ""}`} />
                                                                        <span className="smallImgIcon-Modal-info">
                                                                            {
                                                                                item.bathRooms != undefined ?
                                                                                    item.bathRooms
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-4 d-flex align-items-center justify-content-center">
                                                                        <img src={liftIcon} className={`${item.area != undefined ? "me-1" : ""}`} />
                                                                        {
                                                                            item.area != undefined ?
                                                                                <span className="smallImgIcon-Modal-info">{item.area}<span className="ms-05">m</span><sup>2</sup></span>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    )}>
                                                    <div className={`mb-3 overflow-hidden ${selectedClass}`} onClick={(e) => handlerPropertyClick(item)} id={item.id}>
                                                        <img src={propertyImage} className="sm-prop-img" />
                                                    </div>
                                                </OverlayTrigger>
                                            )
                                        }
                                        else {
                                            let propertyImage = placeholderLarge;
                                            if (item.pictures != undefined && item.pictures.length > 0) {
                                                propertyImage = item.pictures[0].urlLarge;
                                            }
                                            return (
                                                <div className={`form-container position-relative form-container-expanded expanded-estates mb-3 ${selectedClass}`} key={index} onClick={(e) => handlerPropertyClick(item)}>
                                                    <div className="prop-img-container position-relative">
                                                        {
                                                            item.price != undefined ?
                                                                <span className="price-tag">{formatPrice(item.price, item.currency)}</span>
                                                                :
                                                                ""
                                                        }
                                                        <a href={`${variables.Booking_URL}${item.id}/${clientId}/${officeId}/${contactId}`} className={`btn-booking ${isFreeSlotExistForEstate == true ? "" : "d-none"}`} target="_blank">Booking</a>
                                                        <img src={propertyImage} className="property-img" />
                                                    </div>
                                                    <div className="property-name mt-3">
                                                        <span>{item.name} ({item.id})</span>
                                                        <span className="add-slot-link" onClick={handlerAddSlot}>
                                                            <img src={plusBlueIcon} className="me-2" /> Add Slot
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="prop-address my-2">
                                                            {/*<img src={locationPin} className="me-2" />*/}
                                                            <p className="mb-0">
                                                                {
                                                                    (item.address == undefined ? "" : item.address) + " " +
                                                                    (item.number == undefined ? "" : item.number) + ""
                                                                }
                                                            </p>
                                                            <p className="mb-0">
                                                                {
                                                                    (item.zip == undefined ? "" : item.zip) + " " +
                                                                    (item.city == undefined ? "" : item.city)
                                                                }
                                                            </p>
                                                        </div>
                                                        <Dropdown className="estate-drop-menu" show={openDropdownId === item.id} onClick={(e) => handleDropdownToggle(e, item.id, item)}>
                                                            <Dropdown.Toggle className="estate-toggle-menu" variant="light" id="dropdown-basic">
                                                                <FontAwesomeIcon icon={faEllipsisVertical} className="color-blue" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="text-center p-0">
                                                                <button className={`drop-menu-btn text-start ${isFreeSlotExistForEstate == true ? "" : "d-none"}`} onClick={() => copToClipBoard(`${variables.Booking_URL}${item.id}/${clientId}/${officeId}/${contactId}`)}>Copy booking link</button>
                                                                <hr className={`mx-2 my-0 ${isFreeSlotExistForEstate == true ? "" : "d-none"}`} />
                                                                <button className={`drop-menu-btn text-start ${isEstateFutureOrPastFreeSlot ? "" : "d-none"}`} onClick={(e) => handleDeleteAllFreeSlotsClick(e, item.id)}>Delete all free slots</button>
                                                                <hr className={`mx-2 my-0 ${isEstateFutureOrPastFreeSlot && isConfigsSaved ? "" : "d-none"}`} />
                                                                <button className={`drop-menu-btn text-start ${isConfigsSaved == true ? "" : "d-none"}`}
                                                                    onClick={(e) => editEstateConfigurations(e, item.id)}>
                                                                    Edit Form
                                                                </button>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="row prop-detail-row mt-3">
                                                        <div className="col-4 d-flex align-items-center justify-content-center border-end">
                                                            <img src={bedIcon} className={`${item.bedRooms != undefined ? "me-1" : ""}`} />
                                                            <span className="smallImgIcon-Modal-info">
                                                                {
                                                                    item.bedRooms != undefined ?
                                                                        item.bedRooms
                                                                        :
                                                                        <></>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="col-4 d-flex align-items-center justify-content-center border-end">
                                                            <img src={showerIcon} className={`${item.bathRooms != undefined ? "me-1" : ""}`} />
                                                            <span className="smallImgIcon-Modal-info">
                                                                {
                                                                    item.bathRooms != undefined ?
                                                                        item.bathRooms
                                                                        :
                                                                        <></>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="col-4 d-flex align-items-center justify-content-center">
                                                            <img src={liftIcon} className={`${item.area != undefined ? "me-1" : ""}`} />
                                                            {
                                                                item.area != undefined ?
                                                                    <span className="smallImgIcon-Modal-info">{item.area}<span className="ms-05">m</span><sup>2</sup></span>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                        :
                                        <div className="p-3 text-center">
                                            <img src={calLoader} className="cal-loader" />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDeleteWindow showConfirmWindow={showConfirmWindow} handleConfirmWindowClose={handleConfirmWindowClose} callBackConfirmDeleteFreeSlots={callBackConfirmDeleteFreeSlots} displayMessage={confirmWindowMessage} />
            <EventModal showModal={showModal} handleCloseModal={handleClose} showMessage={showMessage} whiseAgents={whiseAgents} saveNewWhiseEvent={saveNewWhiseEvent}
                preview={previewEvent} callBackToUpdateCalendarFilter={callBackToUpdateCalendarFilter} previewEventDetail={previewEventDetail}
                tobeConfirmedIds={tobeConfirmedIds} confirmedIds={confirmedIds} freeSlotIds={freeSlotIds} deleteWhiseCalendarEvent={deleteWhiseCalendarEvent}
                acceptToBeConfirmedEvents={acceptToBeConfirmedEvents} isEstateConfigurationsEdit={isEstateConfigurationsEdit} getEstateIdsHavingConfigSaved={getEstateIdsHavingConfigSaved} />
        </>
    )
}